import { useEffect } from "react";
import ReactGA from "react-ga4";

import generateClientIdGa from "./generate-client-id-ga";
import { useLocation } from "react-router-dom";
import { useAuth } from "../components/AuthContext";

export const trackingId = process.env.REACT_APP_GA_ID;
const appVersion = "APP_VERSION";

const useGoogleAnalytics = () => {
  const { session, setSession } = useAuth();
  const user_id = session?.user?.id;
  const id = user_id;
  const generated_id = generateClientIdGa();

  useEffect(() => {
    if (trackingId) {
      const timeoutId = setTimeout(() => {
        try {
          ReactGA.initialize([
            {
              trackingId,
              gaOptions: {
                anonymizeIp: true,
                clientId: id != null && id !== undefined ? id : generated_id,
              },
            },
          ]);
          ReactGA.set({ app_version: appVersion });
        } catch (error) {
          // Recommend: reporting this error to an error tracking service
          console.log("Error initializing Google Analytics", { Error: error });
        }
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [id]);

  const setOption = (key: string, value: unknown) => {
    ReactGA.set({ [key]: value });
  };

  const setUserId = (userId: string | number) => {
    setOption("userId", userId);
  };

  const sendData = (type: string, data: Object) => {
    ReactGA.send({ hitType: type, ...data });
  };
  const location = useLocation();
  const trackPageView = (pagePath?: string) => {
    if (!pagePath) {
      pagePath = location.pathname + location.search; // pathname and query params
    }

    setOption("app_version", appVersion);
    sendData("pageview", { page: pagePath });
  };
  const trackEvent = (
    category: string,
    action: string,
    label?: string,
    value?: number
  ) => {
    setOption("app_version", appVersion);
    ReactGA.event({ category, action, label, value });
  };

  return {
    setOption,
    setUserId,
    trackPageView,
    trackEvent,
  };
};

export default useGoogleAnalytics;
