import React, { useState, useEffect, useRef, ForwardedRef } from "react";
import "../styles.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SendButton from "./SendButton";
import { useAuth } from "./AuthContext";
import { encode } from "gpt-tokenizer";
import * as Dialog from "@radix-ui/react-dialog";
import crossicon from "src/assets/cross-2.svg";
import { useAppContext } from "./LLMContext";

export interface Message {
  id: number;
  text: string;
  role: "system" | "assistant" | "user";
  isNew?: boolean;
}

export interface ChatHistory {
  id: number;
  title: string;
  messages: Message[];
  creator: string;
  model: string;
  systemInstructions: string;
  temperature: number;
  maxTokens: number;
  stopSequences: string[];
  topP?: number;
  frequencyPenalty?: number;
  presencePenalty?: number;
}

export interface InputTextProps {
  inputText?: string;
  setInputText?: React.Dispatch<React.SetStateAction<string>>;
  selectedCreator?: string;
  selectedModel?: string;
  setSelectedModel?: React.Dispatch<React.SetStateAction<string>>;
  selectedChatId?: number | null;
  chats?: ChatHistory[];
  setChats?: React.Dispatch<React.SetStateAction<ChatHistory[]>>;
  updateChatMessages?: (
    chatId: number,
    messages: Message[],
    creator: string,
    model: string,
    systemInstructions: string,
    temperature: number,
    maxTokens: number,
    stopSequences: string[],
    topP?: number,
    frequencyPenalty?: number,
    presencePenalty?: number
  ) => void;
  handleButtonClick?: () => void;
  startNewChat?: () => void;
  sendMessageButtonRef?: ForwardedRef<HTMLButtonElement>;
  onChatLoaded?: () => void;
  onOpenSettings?: () => void;
  disabled?: boolean;
  systemInstructions?: string;
  setSystemInstructions?: React.Dispatch<React.SetStateAction<string>>;
  temperature?: number;
  setTemperature?: React.Dispatch<React.SetStateAction<number>>;
  maxTokens?: number;
  setMaxTokens?: React.Dispatch<React.SetStateAction<number>>;
  topP?: number;
  setTopP?: React.Dispatch<React.SetStateAction<number>>;
  stopSequences?: string[];
  setStopSequences?: React.Dispatch<React.SetStateAction<string[]>>;
  frequencyPenalty?: number;
  setFrequencyPenalty?: React.Dispatch<React.SetStateAction<number>>;
  presencePenalty?: number;
  setPresencePenalty?: React.Dispatch<React.SetStateAction<number>>;

  withSystemTOF?: boolean;
  loadingChatData?: boolean;
}

const InputText: React.FC<InputTextProps> = ({
  inputText,
  setInputText,
  selectedChatId,
  chats,
  updateChatMessages,
  handleButtonClick,
  startNewChat = () => { },
  sendMessageButtonRef,
  systemInstructions,
  setSystemInstructions,
  temperature,
  setTemperature,
  maxTokens,
  setMaxTokens,
  topP,
  setTopP,
  stopSequences,
  setStopSequences,
  frequencyPenalty,
  setFrequencyPenalty,
  presencePenalty,
  setPresencePenalty,
}) => {
  const {
    selectedModel,
    setSelectedModel,
    selectedCreator,
    setSelectedCreator,
    selectedLLM,
    setSelectedLLM,
  } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { session } = useAuth();
  const [userId, setUserId] = useState(null);
  const [isLimit, setIsLimit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const openDialog = () => setIsOpen(true);
  const [isEmptyCredits, setIsEmptyCredits] = useState<boolean>(false);
  const [isSubscribe, setIsSubscribe] = useState<boolean>(false);
  const [loadingIsSubscribe, setLoadingIsSubscribe] = useState<boolean>(true);
  const [modelId, setModelId] = useState<number | null>(null);
  const [tokenLimitReach, setTokenLimitReach] = useState(false);
  const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
  const fetchModelId = async (selectedModel: string) => {
    try {
      const data = await axios.get(`${urlEnv}fetch-model-id`, { params: { selectedModel } });
      setModelId(data.data.model_id);
    } catch (error) {
      console.error("Error fetching model ID:", error);
    }
  };
  useEffect(() => {
    if (session?.user?.id && session?.user?.id !== userId) {
      setUserId(session.user.id);
    }
  }, [session]);

  useEffect(() => {
    if (selectedModel) {
      fetchModelId(selectedModel);
    }
  }, [selectedModel]);

  const handleSettingsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case "inputText":
        setInputText && setInputText(value);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (
      selectedChatId !== null &&
      handleButtonClick !== null &&
      inputText.trim() !== ""
    ) {
      handleButtonClick();
    }
  }, [handleButtonClick, navigate]);

  const removeAllItems = () => {
    sessionStorage.removeItem("systemInstructions");
    sessionStorage.removeItem("temperature");
    sessionStorage.removeItem("maxTokens");
    sessionStorage.removeItem("topP");
    sessionStorage.removeItem("stopSequences");
    sessionStorage.removeItem("frequencyPenalty");
    sessionStorage.removeItem("presencePenalty");
    sessionStorage.removeItem("selectedModel");
    sessionStorage.removeItem("selectedCreator");
  };

  const storedSystemInstructions = sessionStorage.getItem("systemInstructions") || "";
  const storedTemperature = Number(sessionStorage.getItem("temperature")) || 1;
  const storedMaxTokens = Number(sessionStorage.getItem("maxTokens")) || 100;
  const storedTopP = Number(sessionStorage.getItem("topP")) || 1;
  const storedStopSequences = JSON.parse(
    sessionStorage.getItem("stopSequences") || "[]"
  );
  const storedFrequencyPenalty =
    Number(sessionStorage.getItem("frequencyPenalty")) || 0;
  const storedPresencePenalty =
    Number(sessionStorage.getItem("presencePenalty")) || 0;

  const storedSelectedModel = sessionStorage.getItem("selectedModel");
  const storedSelectedCreator = sessionStorage.getItem("selectedCreator");

  useEffect(() => {
    storedSystemInstructions && setSystemInstructions(storedSystemInstructions);
    storedTemperature && setTemperature(storedTemperature);
    storedMaxTokens && setMaxTokens(storedMaxTokens);
    storedTopP && setTopP(storedTopP);
    storedStopSequences && setStopSequences(storedStopSequences);
    storedFrequencyPenalty && setFrequencyPenalty(storedFrequencyPenalty);
    storedPresencePenalty && setPresencePenalty(storedPresencePenalty);
    storedSelectedModel && setSelectedModel(storedSelectedModel);
    storedSelectedCreator && setSelectedCreator(storedSelectedCreator);
  }, []);

  const fetchAutoRecharge = async () => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    try {
      const response = await axios.get(`${urlEnv}fetch-auto-recharge`, {
        params: { user_id: userId },
      });

      if (response) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error("fetchAutoRecharge: ", error);
    }
  };

  const fetchCreditsConversion = async () => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    try {
      const response = await axios.get(`${urlEnv}fetch-credits-conversion`);

      if (response.data) {
        return response.data.itemized_per_credits;
      } else {
        return null;
      }
    } catch (error) {
      console.error("fetchCreditsConversion error: ", error);
    }
  };

  const fetchCustomerId = async (pm: string) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    try {
      const response = await axios.get(`${urlEnv}get-payment-method`, {
        params: { existingPaymentMethodId: pm },
      });
      if (response) {
        console.log("fetchCustomerId", response.data.paymentMethod.customer);
        return response.data.paymentMethod.customer;
      }
    } catch (error) {
      console.error("error get-payment-method:", error);
    }
  };

  const calculteAutoRecharge = async (response: any) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    if (response.data?.total_credits) {
      const autoRechargeData = await fetchAutoRecharge();
      if (autoRechargeData) {
        if (autoRechargeData.active === "false") {
          return;
        } else if (autoRechargeData.active === "true") {
          const creditsConversionData = await fetchCreditsConversion();
          const exceededThreshold =
            response.data?.total_credits <=
            autoRechargeData.threshold_amount / creditsConversionData;

          if (exceededThreshold) {
            const pm = autoRechargeData.payment_method;
            const amount = autoRechargeData.recharge_amount;
            const customerId = await fetchCustomerId(pm);

            try {
              const response = await axios.post(
                `${urlEnv}create-checkout-session`,
                {
                  payment_method: pm,
                  customerId: customerId,
                  recharge_amount: amount,
                  user_id: userId,
                }
              );
              response && console.log("AUTO RECHARGE CREDITS", response.data);
              if (response.data) {
                if (window.fbq) {
                  window.fbq("track", "Purchase", {
                    value: response.data.amount,
                    currency: "USD",
                  });
                }
              }
            } catch (error) {
              console.error("error triggering auto recharge:", error);
            }
          } else {
            return;
          }
        }
      }
    }
  };

  const checkCreditsOfUser = async () => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    try {
      if (userId) {
        const response = await axios.get(`${urlEnv}checkCreditsOfUser`, {
          params: { user_id: userId },
        });
        console.log("checkCreditsOfUser", response);
        if (response.data) {
          if (response.data?.total_credits <= 1) {
            setIsSubscribe(false);
            setIsEmptyCredits(true);
          } else {
            setIsSubscribe(true);
            setIsEmptyCredits(false);
          }
          calculteAutoRecharge(response);
        }
        setLoadingIsSubscribe(false);
      }
    } catch (err) {
      console.log(`error fetching checkCreditsOfUser: ${err.message}`);
    }
  };

  useEffect(() => {
    if (userId) {
      setLoadingIsSubscribe(true);
      checkCreditsOfUser();
    }
  }, [userId]);

  const sendMessage = async () => {
    if (!session) {
      alert("signup and sign in to use panelsAI");
      return;
    }

    if (isEmptyCredits) {
      alert("Insufficient credits. would you like to refill your credits? ");
      return;
    } else if (!loadingIsSubscribe && !isSubscribe) {
      alert("Subscribe to use Panels'AI");
      return;
    }
    if (inputText.trim() !== "") {
      if (selectedModel !== "Model" && selectedCreator !== "Creator")
        startNewChat();
    } else {
      alert("Please input text");
      return;
    }
    if (selectedCreator && selectedModel) {
      try {
        setIsLoading(true);
        const chat = chats.find((chat) => chat.id === selectedChatId);
        if (!chat) {
          setIsLoading(false);
          return;
        }
        const tokens = encode(inputText).length;
        const response = await axios.post(`${urlEnv}check-model`, {
          creator: selectedCreator,
          model: selectedModel,
          tokens: tokens
        });
        if (response.data === "No Model Found") {
          setIsLoading(false);
          alert("Please select a Model");
        } else if (response.data === "No Creator Found") {
          setIsLoading(false);
          alert("Creator not found");
        } else if (response.data === "Token Limit Reached") {
          openDialog();
          setInputText("");
          sessionStorage.removeItem("inputText");
          setIsLoading(false);
        } else {
          const highestId = chat.messages.reduce((maxId, message) => Math.max(maxId, message.id), 0);
          const messageWithSystemInstuction: Message = { id: highestId + 1, text: systemInstructions, role: "system" };
          const newMessage: Message = { id: messageWithSystemInstuction.id + 1, text: inputText, role: "user", isNew: true };
          let updatedMessages = [...chat.messages, newMessage];
          const loaderMessage: Message = { id: newMessage.id + 1, text: "loading", role: "assistant", isNew: true };
          let messagesWithLoader = [...updatedMessages, loaderMessage];
          updateChatMessages(chat.id, messagesWithLoader, selectedCreator, selectedModel, systemInstructions, temperature, maxTokens, stopSequences, topP, frequencyPenalty, presencePenalty);
          try {
            let messageContext = [
              { role: "system", content: systemInstructions },
              ...await fetchChatThreadIDLogs(chat.id, userId, inputText, tokenLimitReach)
            ];
            if (tokenLimitReach) {
              const userLastMessage = chat.messages[chat.messages.length - 2];
              const aiLastMessage = chat.messages[chat.messages.length - 1];
              chat.messages = [userLastMessage, aiLastMessage];
              updatedMessages = [...chat.messages, newMessage];
              messagesWithLoader = [...updatedMessages, loaderMessage];
              updateChatMessages(chat.id, messagesWithLoader, selectedCreator, selectedModel, systemInstructions, temperature, maxTokens, stopSequences, topP, frequencyPenalty, presencePenalty);
              localStorage.setItem("chats", JSON.stringify(chats));
            }
            const result = await axios.post(`${urlEnv}call${response.data.suburl?.toLowerCase()}`, {
              model: selectedModel,
              messages: messageContext,
              temperature: temperature,
              max_tokens: maxTokens,
              top_p: topP,
              stop_sequences: stopSequences.length > 0 ? stopSequences : undefined,
              frequency_penalty: frequencyPenalty,
              presence_penalty: presencePenalty,
            });
            const botMessage: Message = {
              id: loaderMessage.id,
              text: result.data.message,
              role: "assistant",
              isNew: true,
            };
            const finalMessages = [...updatedMessages, botMessage];
            updateChatMessages(
              chat.id,
              finalMessages,
              selectedCreator,
              selectedModel,
              systemInstructions,
              temperature,
              maxTokens,
              stopSequences,
              topP,
              frequencyPenalty,
              presencePenalty
            );
            setIsLoading(false);
            sessionStorage.removeItem("inputText");
            const completion_tokens = result.data.completion_tokens;
            const prompt_tokens = result.data.prompt_tokens;
            const total_token = completion_tokens + prompt_tokens;
            console.log("Main Response", result);
            console.log("total_token", total_token);
            console.log("prompt_tokens", prompt_tokens);
            console.log("completion_tokens", completion_tokens);
            if (total_token >= response.data.limit - 3000) {
              setTokenLimitReach(!tokenLimitReach);
            }
            const updateUserTokenPromise = axios
              .post(`${process.env.REACT_APP_SERVER_ACCESS}update-user-credits`, {
                chat_id: chat.id,
                completion_tokens: completion_tokens,
                prompt_tokens: prompt_tokens,
                user_id: userId,
                selectedModel: selectedModel,
                selectedCreator: selectedCreator,
                total_token_used: total_token,
              })
              .catch((error) => {
                console.log("Error updating user token:", error);
              });
            const saveQtoDBPromise = saveQtoDB(
              finalMessages,
              chat.id,
              total_token,
              completion_tokens,
              prompt_tokens,
              modelId,
              tokenLimitReach
            );
            await Promise.all([
              saveQtoDBPromise,
              updateUserTokenPromise,
              removeAllItems,
            ]);
            await checkCreditsOfUser();
          } catch (error) {
            console.error("Error sending message:", error);
            setIsLoading(false);
            sessionStorage.removeItem("inputText");
          }
        }
      } catch (error) {
        console.error("Error sending request:", error);
        alert("An error occurred while connecting to the backend.");
      }
    }
  };
  const fetchChatThreadIDLogs = async (
    chat_id,
    user_id,
    inputText,
    tokenLimitReach,
    withSystemTOF?
  ) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "getThreadId";

    const response = await axios.get(urlEnv, {
      params: {
        chat_id: chat_id,
        user_id: user_id,
      },
    });
    let messageContext = [];
    if (systemInstructions && !withSystemTOF) {
      messageContext.push({ role: "system", content: systemInstructions });
    }
    if (response.data) {
      messageContext = response.data.messages.map((msg) => ({
        role:
          msg.role === "user"
            ? "user"
            : msg.role === "assistant"
              ? "assistant"
              : msg.role === "system"
                ? "system"
                : "unknown",
        content: msg.text,
      }));
    }

    console.log("messageContext", messageContext);

    if (tokenLimitReach) {
      setTokenLimitReach(false);
      messageContext = messageContext.slice(-2);
      alert("conversation history UPDATED!");
    }
    messageContext.push({ role: "user", content: inputText });
    setInputText("");
    return messageContext;
  };

  const saveQtoDB = async (
    finalMessages,
    chatID,
    total_token_used,
    completion_tokens,
    prompt_tokens,
    model_id,
    tokenLimitReach
  ) => {
    console.log("finalMessages", finalMessages);

    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "saveThread";
    try {
      const response = await axios.post(urlEnv, {
        log: finalMessages,
        user_id: userId,
        model_id: modelId,
        chat_id: chatID,
        total_token_used: total_token_used,
        completion_tokens: completion_tokens,
        prompt_tokens: prompt_tokens,
        selectedCreator: selectedCreator,
        selectedModel: selectedModel,
        tokenLimitReach: tokenLimitReach,
        instruction: systemInstructions,
        temperature: temperature,
        max_tokens: maxTokens,
        top_p: topP,
        stop_sequence: stopSequences.length > 0 ? stopSequences : null,
        frequency_penalty: frequencyPenalty,
        presence_penalty: presencePenalty,
      });
    } catch (error) {
      if (error.response) {
        console.error("Error saving query:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        return;
      } else {
        e.preventDefault();
        sendMessage();
      }
    }
  };
  const textareaRef = useRef(null);
  useEffect(() => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight + 1}px`;
  }, [inputText]);
  return (
    <div className="flex-div mb-10">
      <div className="input-container">
        <textarea
          ref={textareaRef}
          placeholder="Ask PanelsAI something..."
          name="inputText"
          value={inputText}
          onKeyDown={handleKeyDown}
          onChange={handleSettingsChange}
          disabled={isLoading}
          rows={1}
        />
        <SendButton
          ref={sendMessageButtonRef}
          onClick={sendMessage}
          disabled={isLoading}
        />
      </div>
      <Dialog.Root
        open={isOpen}
        onOpenChange={(open) => {
          setIsOpen(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>
        <Dialog.Portal>
          <div className="overlay"></div>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent DialogCustomWidth-signup">
            <Dialog.Title className="DialogTitle"></Dialog.Title>
            <Dialog.Description className="DialogDescription"></Dialog.Description>

            <div className="subscription-panel">
              <h2>Error generating response</h2>
              <p className="description">
                The message you are trying to submit is too long. Please submit
                something shorter.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              <Dialog.Close asChild></Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <img
                  src={crossicon}
                  alt="icon"
                  style={{
                    width: "20px",
                    height: "20px",

                    filter: "invert(1)",
                  }}
                />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};
export default InputText;