import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../styles.css";

export interface DropdownllmProps {
  selectedModel: string;
  setSelectedModel: (model: string) => void;
  selectedCreator: string;
  setSelectedCreator: (creator: string) => void;
  selectedLLM: string;
  setSelectedLLM: (llm: string) => void;
  maxToken: number | null;
  setMaxToken: (limit: number | null) => void;
  disabled: boolean;
}

const Dropdownllm: React.FC<DropdownllmProps> = ({
  selectedModel,
  setSelectedModel,
  selectedCreator,
  setSelectedCreator,
  selectedLLM,
  setSelectedLLM,
  maxToken,
  setMaxToken,
  disabled,
}) => {

  const [creators, setCreators] = useState<{ creator: string; models: { model_id: number; model_name: string; max_token: number }[] }[]>([]);
  const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownRef2 = useRef<HTMLDivElement>(null);
  const dropdownRef3 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await axios.get(`${urlEnv}fetch-models`);
        setCreators(response.data);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };
    fetchModels();
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      dropdownRef2.current &&
      !dropdownRef2.current.contains(event.target as Node) &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
      setIsOpenCreator(false);
      setIsOpenModel(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCreator, setIsOpenCreator] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsOpenModel(false);
    setIsOpenCreator(false);
  };

  const toggleCreatorDropdown = () => {
    setIsOpenCreator(!isOpenCreator);
    setIsOpenModel(false);
  };

  const toggleModelDropdown = () => {
    setIsOpenModel(!isOpenModel);
    setIsOpenCreator(false);
  };

  return (
    <>
      <div className="flex-div flex-div-between">
        <div className="dropdown-container" ref={dropdownRef}>
          <input
            className="dropbtn"
            type="button"
            onClick={disabled ? undefined : toggleDropdown}
            value={!selectedLLM ? "Select Model" : selectedLLM}
          />
          {isOpen && (
            <div className="dropdown-content-CC">
              <div className="llm-btn" ref={dropdownRef2} onClick={toggleCreatorDropdown}>
                <input type="button" value={selectedCreator || "Select Creator"} />
                <svg className="dropdown-svg" viewBox="0 0 320 512">
                  <path fill="#ffffff" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </div>
              <div className="llm-btn" ref={dropdownRef3} onClick={toggleModelDropdown}>
                <input type="button" value={selectedModel || "Select Model"} />
                <svg className="dropdown-svg" viewBox="0 0 320 512">
                  <path fill="#ffffff" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              </div>
            </div>
          )}

          {isOpenCreator && (
            <div className="dropdown-content" ref={dropdownRef2}>
              {creators.map((item) => (
                <input
                  key={item.creator}
                  type="button"
                  value={item.creator}
                  onClick={() => {
                    setSelectedCreator(item.creator);
                    setSelectedModel("");
                    setMaxToken(null);
                    setIsOpenCreator(false);
                  }}
                />
              ))}
            </div>
          )}

          {isOpenModel && selectedCreator && (
            <div className="dropdown-content" ref={dropdownRef3}>
              {creators
                .find((item) => item.creator === selectedCreator)
                ?.models.map((model) => (
                  <input
                    key={model.model_id}
                    type="button"
                    value={`${model.model_name}`}
                    onClick={() => {
                      setSelectedModel(model.model_name);
                      setSelectedLLM(`${selectedCreator}:${model.model_name}`);
                      setMaxToken(model.max_token);
                      setIsOpenModel(false);
                      setIsOpen(false);
                    }}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Dropdownllm;