import React, { createContext, useContext, useEffect, useState } from "react";
import "src/styles.css";
import NavigationBar from "../screens/Navigationbar";
import WelcomeSection from "../screens/WelcomeSection";
import AppFooter from "../screens/Footer";
import logo from "../assets/logo.png";
import { styled } from "@mui/system";
import eyeopen from "src/assets/eye-regular.svg";
import eyeclose from "src/assets/eye-slash-regular.svg";
import {
  Containerdiv,
  Formdiv,
  Titlediv,
  Labeldiv,
  Inputdiv,
  Linkdiv,
  Buttondiv,
  Footerdiv,
  PasswordContainerdiv,
  ToggleButtondiv,
} from "./Signup";
import errorimage from "src/assets/circle-exclamation-solid.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Dialog from "@radix-ui/react-dialog";
import crossicon from "src/assets/cross-2.svg";

export const ErrorImage = () => {
  return <img src={errorimage} alt="error" className="error-image" />;
};

interface UpdatePasswordForm {
  password: string;
}

const ResetPassword: React.FC = () => {
  // const queryParams = new URLSearchParams(window.location.search);
  // const accessToken = queryParams.get("access_token");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  // if (accessToken) {
  //   localStorage.setItem("access_token", accessToken);
  // }
  // console.log("accessToken", accessToken);

  const [form, setForm] = useState<UpdatePasswordForm>({
    password: "",
  });
  const [errors, setErrors] = useState<UpdatePasswordForm>({
    password: "",
  });

  const validatePassword = async (password: string): Promise<string> => {
    if (!password) {
      return "Password is required";
    }
    if (!(password.length >= 12)) {
      return "Password must be at least 12 characters";
    }

    return undefined;
  };
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const passwordError = await validatePassword(form.password);

    if (passwordError) {
      setErrors({
        password: passwordError,
      });
    } else {
      setErrors({
        password: undefined,
      });

      // const { data, error } = await supabase.auth.updateUser({
      //   password: form.password,
      // });

      // if (error) {
      //   console.error("Error updating password:", error.message);
      //   alert(error.message);
      //   return { success: false, message: error.message };
      // }

      // console.log("Password updated successfully:", data);
      openDialog();

      return { success: true, message: "Password updated successfully" };
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleDialogClose = () => {
    navigate("/chat");
  };

  return (
    <>
      <NavigationBar />
      <div className="content-signup-login">
        <div className="signup-login">
          <div className="welcome-section2">
            <h1>Welcome to PanelsAI</h1>&nbsp;
            <img src={logo} alt="Logo" className="welcome-logo" />
          </div>
        </div>

        <Containerdiv>
          <Formdiv onSubmit={handleSubmit}>
            <Titlediv>Reset password</Titlediv>
            <PasswordContainerdiv>
              <Labeldiv htmlFor="password" style={{ marginTop: "12px" }}>
                Password
              </Labeldiv>
              <div
                className={`password-input ${errors.password ? "input-error" : ""
                  }`}
              >
                <Inputdiv
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  placeholder="Enter new password"
                />

                <ToggleButtondiv
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <img
                    src={showPassword ? eyeopen : eyeclose}
                    alt="toggle visibility"
                  />
                </ToggleButtondiv>
              </div>
              {errors.password && (
                <label className="error">
                  <ErrorImage />
                  {errors.password}
                </label>
              )}
            </PasswordContainerdiv>
            <Buttondiv type="submit">Confirm</Buttondiv>
          </Formdiv>
        </Containerdiv>
      </div>
      <AppFooter />
      <Dialog.Root
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) {
            handleDialogClose();
          }
          setIsOpen(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>
        <Dialog.Portal>
          <div className="overlay"></div>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent DialogCustomWidth-signup">
            <Dialog.Title className="DialogTitle"></Dialog.Title>
            <Dialog.Description className="DialogDescription"></Dialog.Description>

            <div className="subscription-panel">
              <h2>Password succesfully changed</h2>
              <p className="description">Close this message to start message</p>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              <Dialog.Close asChild></Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <img
                  src={crossicon}
                  alt="icon"
                  style={{
                    width: "20px",
                    height: "20px",

                    filter: "invert(1)",
                  }}
                />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default ResetPassword;
