import React, { useCallback, useEffect, useRef, useState } from "react";
import NavigationBar from "./Navigationbar";
import WelcomeSection from "./WelcomeSection";
import AppFooter from "./Footer";
import "src/styles.css";
import { styled } from "@mui/system";
import { CssBaseline, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import * as Tabs from "@radix-ui/react-tabs";
import crossicon from "src/assets/cross-2.svg";
import * as Dialog from "@radix-ui/react-dialog";
import { CloseButton, IconContainer1, MainContent, Root, SideDrawer, TopAppBar } from "../components/ChatScreenWindow";
import Sidebar from "../components/SideBar";
import { ChatHistory, Message } from "../components/SendMessage";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../components/AuthContext";
import SettingsPanel from "../components/SettingsPanel";
import { useAppContext } from "../components/LLMContext";
import SubscriptionPanel, { BuyCreditsPanel } from "../components/SubscriptionPanel";

const ContentContainer = styled("div")<{
  leftOpen: boolean;
  rightOpen: boolean;
}>(({ leftOpen, rightOpen }) => ({
  margin: "70px 20px 0 20px",
  transition:
    "margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin-right 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  backgroundColor: "#1f1f1f",
}));

const WelcomeScreen: React.FC = () => {
  const {
    selectedModel,
    setSelectedModel,
    selectedCreator,
    setSelectedCreator,
    selectedLLM,
    setSelectedLLM,
    maxToken,
    setMaxToken,
  } = useAppContext();

  const { session, setSession } = useAuth();
  const user_id = session?.user?.id;
  console.log("WelcomeScreen user_id", user_id);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let type = searchParams.get("type");
  let success = searchParams.get("success");
  useEffect(() => {
    if (type !== null || type !== undefined) {
      sessionStorage.setItem("-promo-type", type);
    }
  }, [type]);

  useEffect(() => {
    if (success !== null || success !== undefined) {
      sessionStorage.setItem("-success-type", success);
    }
  }, [success]);
  const paramsType1 = sessionStorage.getItem("-promo-type");
  const paramsType2 = sessionStorage.getItem("-success-type");

  useEffect(() => {
    if (paramsType1 === "payment" && paramsType2 === "true") {
      console.log("parayoyo", paramsType1);
      console.log("parayoyo", paramsType2);

      if (window.fbq) {
        window.fbq("track", "StartTrial", {
          value: 1.0,
          currency: "USD",
        });

        sessionStorage.removeItem("-promo-type");
        sessionStorage.removeItem("-success-type");
      }
    }
  }, [paramsType1, paramsType2]);

  useEffect(() => {
    if (session) {
      if (!localStorage.getItem("hasReloaded")) {
        localStorage.setItem("hasReloaded", "true");
        window.location.reload();
      }
      setSession(session);
    }
  }, [session]);

  const handleLogout = async () => {
    try {
      console.log("Logging out...");
      await axios.post(
        `${process.env.REACT_APP_SERVER_ACCESS}logout`,
        {},
        { withCredentials: true }
      );
      setSession(null);
      localStorage.removeItem("isSub");
      localStorage.removeItem("chats");
      localStorage.removeItem("userToken");
      sessionStorage.removeItem("user_id");
      window.location.href = "/login";
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  useEffect(() => {
    if (session !== "loading" && !session?.user) {
      navigate("/login");
    }
  }, [session]);

  useEffect(() => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;

    const checkIfNewUserAndSubscriptionType = async () => {
      try {
        const response = await axios.get(
          `${urlEnv}checkIfNewUserAndSubscriptionType`,
          {
            params: { user_id: user_id },
          }
        );
        if (response?.data?.success) {
          handleLogout();
          console.log("set user role type");
        }
      } catch (err) {
        console.error("error fething checkIfNewUser:", err.message);
      }
    };
    checkIfNewUserAndSubscriptionType();
  }, [user_id]);

  const [userSubstype, setUserSubstype] = useState<number>(0);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const newUserPromo = async (type: string) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    console.log("priceID", process.env.REACT_APP_LIFETIME_PRICE_ID);
    const response = await axios.post(`${urlEnv}create-checkout-session`, {
      product_id: process.env.REACT_APP_LIFETIME_PRICE_ID,
      user_id: user_id,
      type: type,
    });
    console.log(response);
    window.location.href = response.data.url;
  };
  const [openModalPromo, setOpenModalPromo] = useState(false);

  useEffect(() => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    const checkIfNewUserAndSubscriptionType = async () => {
      try {
        const response = await axios.get(
          `${urlEnv}checkIfNewUserAndSubscriptionType`,
          {
            params: { user_id: user_id },
          }
        );
        if (response?.data?.subscription) {
          setUserSubstype(response.data.subscription);
          console.log("set user subs type", response.data.subscription);
        } else {
          //setUserSubstype("");
        }

        if (response?.data?.success) {
          setIsNewUser(true);

          console.log("set user role type");
        } else {
          setIsNewUser(false);
        }
      } catch (err) {
        console.error("error fething checkIfNewUser:", err.message);
      }
    };
    checkIfNewUserAndSubscriptionType();
  }, [openModalPromo]);

  useEffect(() => {
    const promoApplied = localStorage.getItem("promoApplied");

    const timer = setTimeout(() => {
      if (!promoApplied && openModalPromo === true) {
        newUserPromo("lifetime");
      }
    }, 10000);
    return () => clearTimeout(timer);
  }, [openModalPromo]);

  useEffect(() => {
    if (isNewUser) {
      const handleOpenModal = () => {
        setOpenModalPromo(true);
        localStorage.setItem("lastModalShown", Date.now().toString());
      };
      const lastModalShown = localStorage.getItem("lastModalShown");
      const oneHourInMillis = 30000;
      const now = Date.now();
      if (!lastModalShown || now - parseInt(lastModalShown) > oneHourInMillis) {
        handleOpenModal();
      }

      const intervalId = setInterval(() => {
        handleOpenModal();
      }, oneHourInMillis);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isNewUser]);

  const [leftOpen, setLeftOpen] = React.useState(false);
  const [rightOpen, setRightOpen] = React.useState(false);
  const [isDeletingChats, setIsDeletingChats] = useState(false);

  const [chats, setChats] = useState<ChatHistory[]>(() => {
    const savedChats = localStorage.getItem("chats");
    return savedChats ? JSON.parse(savedChats) : [];
  });
  useEffect(() => {
    localStorage.setItem("chats", JSON.stringify(chats));
  }, [chats]);

  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const location = useLocation();
  const fromWelcomeScreen = location.state?.fromWelcomeScreen;
  const handleLeftDrawerToggle = () => {
    setLeftOpen(!leftOpen);
  };

  const handleRightDrawerToggle = () => {
    setRightOpen(!rightOpen);
  };

  const handleNewChat = useCallback(() => {
    const maxId = chats.reduce((max, chat) => Math.max(max, chat.id), -0);

    const newChat: ChatHistory = {
      id: maxId + 1,
      title: `Chat ${maxId + 1}`,
      messages: [],
      creator: selectedCreator,
      model: selectedModel,
      systemInstructions: systemInstructions,
      temperature: temperature,
      maxTokens: maxTokens,
      stopSequences: stopSequences,
      topP: topP,
      frequencyPenalty: frequencyPenalty,
      presencePenalty: presencePenalty,
    };
    console.log("length:11 ", chats.length);
    setChats((prevChats) => [...prevChats, newChat]);
    setSelectedChatId(newChat.id);
    console.log("yawa nga chat sa welcome screen", chats);
  }, [chats.length]);

  const removeAllItems = () => {
    sessionStorage.removeItem("systemInstructions");
    sessionStorage.removeItem("temperature");
    sessionStorage.removeItem("maxTokens");
    sessionStorage.removeItem("topP");
    sessionStorage.removeItem("stopSequences");
    sessionStorage.removeItem("frequencyPenalty");
    sessionStorage.removeItem("presencePenalty");
  };
  const deleteChat = async (chatId: number) => {
    removeAllItems();
    // console.log("chatId", chatId);
    setIsDeletingChats(true);

    const threadChatId = await fetchChatThreadID(chatId, user_id);
    // console.log("chatThreadIDchatThreadID", threadChatId);

    if (threadChatId) {
      const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
      // console.log("urlEnv", urlEnv);
      try {
        const response = await axios.post(`${urlEnv}delete-chat-db`, {
          chatId: chatId,
          chatThreadID: threadChatId,
          user_id: user_id,
        });
        if (response) {
          // console.log(response.data);
        } else {
          // console.log("chat from DB deleted");
        }
      } catch (error) {
        console.log(error);
      }
    }

    const updatedChats = chats.filter((chat) => chat.id !== chatId);
    console.log("length:10 ", chats.length);
    setChats(updatedChats);
    console.log("chatIdchatIdchatId", chatId);
    if (selectedChatId === chatId) {
      setSelectedChatId(
        updatedChats?.length > 0
          ? updatedChats[updatedChats.length - 1].id
          : null
      );
    }

    setIsDeletingChats(false);
  };
  const fetchChatThreadID = async (chat_id, user_id) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "getThreadId";

    const response = await axios.get(urlEnv, {
      params: {
        chat_id: chat_id,
        user_id: user_id,
      },
    });

    console.log(response.data.thread_id);

    if (response.data) {
      return response.data.thread_id;
    } else if (!response.data) {
      return undefined;
    }
  };
  const [isFirstNavigation, setIsFirstNavigation] = useState(true);
  const handleSelectChat = (chatId: number) => {
    setSelectedChatId(chatId);
    console.log("chatId", chatId);
    chats.filter((chat) => {
      if (chat.id === chatId) {
        console.log("chat.id", chat.id);
        setSelectedModel(chat.model);
        setSelectedCreator(chat.creator);
        setSystemInstructions(chat.systemInstructions);
        setTemperature(chat.temperature);
        setMaxTokens(chat.maxTokens);
        setStopSequences(chat.stopSequences);
        setTopP(chat.topP);
        setFrequencyPenalty(chat.frequencyPenalty);
        setPresencePenalty(chat.presencePenalty);
        if (isFirstNavigation) {
          // Skip navigation the first time
          setIsFirstNavigation(false);
          console.log("Skipping navigation for the first time.");
        } else {
          // Navigate on subsequent selections
          navigate("/chat", { state: { selectedChatId: chatId } });
          console.log("Navigating to chat:", chatId);
        }
      }
    });
  };
  const [systemInstructions, setSystemInstructions] = useState<string>(() => {
    const savedSystemInstructions =
      sessionStorage.getItem("systemInstructions");
    return savedSystemInstructions || "";
  });
  const [temperature, setTemperature] = useState<number>(() => {
    const savedTemperature = sessionStorage.getItem("temperature");
    return savedTemperature ? parseFloat(savedTemperature) : 1;
  });
  const [maxTokens, setMaxTokens] = useState<number>(() => {
    const savedMaxTokens = sessionStorage.getItem("maxTokens");
    return savedMaxTokens ? parseInt(savedMaxTokens) : 100;
  });
  const [topP, setTopP] = useState<number>(() => {
    const savedTopP = sessionStorage.getItem("topP");
    return savedTopP !== null ? parseFloat(savedTopP) : 1;
  });

  const [stopSequences, setStopSequences] = useState<string[]>(() => {
    const savedStopSequences = sessionStorage.getItem("stopSequences");
    return savedStopSequences ? JSON.parse(savedStopSequences) : [];
  });

  const [frequencyPenalty, setFrequencyPenalty] = useState<number>(() => {
    const savedFrequencyPenalty = sessionStorage.getItem("frequencyPenalty");
    return savedFrequencyPenalty !== null
      ? parseFloat(savedFrequencyPenalty)
      : 0;
  });

  const [presencePenalty, setPresencePenalty] = useState<number>(() => {
    const savedPresencePenalty = sessionStorage.getItem("presencePenalty");
    return savedPresencePenalty !== null ? parseFloat(savedPresencePenalty) : 0;
  });

  useEffect(() => {
    sessionStorage.setItem("systemInstructions", systemInstructions);
  }, [systemInstructions]);

  useEffect(() => {
    sessionStorage.setItem("temperature", temperature.toString());
  }, [temperature]);

  useEffect(() => {
    sessionStorage.setItem("maxTokens", maxTokens.toString());
  }, [maxTokens]);

  useEffect(() => {
    sessionStorage.setItem("topP", topP.toString());
  }, [topP]);

  useEffect(() => {
    sessionStorage.setItem("frequencyPenalty", frequencyPenalty.toString());
  }, [frequencyPenalty]);

  useEffect(() => {
    sessionStorage.setItem("presencePenalty", presencePenalty.toString());
  }, [presencePenalty]);

  useEffect(() => {
    sessionStorage.setItem("selectedModel", selectedModel);
    sessionStorage.setItem("selectedCreator", selectedCreator);
  }, [selectedModel, selectedCreator]);

  const [hasSentInitialMessage, setHasSentInitialMessage] = useState(() => {
    return sessionStorage.getItem("hasSentInitialMessage") === "true";
  });

  const [isChatLoaded, setIsChatLoaded] = useState(false);
  const sendMessageButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (
      !hasSentInitialMessage &&
      fromWelcomeScreen &&
      isChatLoaded &&
      sendMessageButtonRef.current
    ) {
      sendMessageButtonRef.current.click();

      sessionStorage.setItem("hasSentInitialMessage", "true");
    }
  }, [fromWelcomeScreen, isChatLoaded]);

  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);

  const isWelcomeScreen = location.pathname === "/welcome";

  useEffect(() => {
    const selectedChats = chats.filter((chat) => chat.id === selectedChatId);

    selectedChats.map((chat, index) => {
      if (chat.messages && chat?.messages?.length > 0) {
        setIsDropdownDisabled(true);
      } else {
        setIsDropdownDisabled(false);
      }

      isWelcomeScreen && setIsDropdownDisabled(false);
    });
  }, [selectedChatId, chats]);

  const updateChatMessages = (
    chatId: number,
    messages: Message[],
    creator: string,
    model: string,
    systemInstructions: string,
    temperature: number,
    maxTokens: number,
    stopSequences: string[],
    topP?: number,
    frequencyPenalty?: number,
    presencePenalty?: number
  ) => {
    const updatedChats = chats.map((chat) => {
      if (chat.id === chatId) {
        return {
          ...chat,
          messages: messages === null ? [...chat.messages] : messages,
          creator,
          model,
          systemInstructions,
          temperature,
          maxTokens,
          stopSequences,
          topP,
          frequencyPenalty,
          presencePenalty,
        };
      }
      return chat;
    });
    console.log("length:9", chats.length);
    setChats(updatedChats);
  };
  const [data, setData] = useState(null);
  const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${urlEnv}fetch-credits-history`, {
        user_id: user_id,
      });
      //console.log("responseasdasdsad", response.data);
      setData(response.data);
    } catch (err) {
      console.log(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (user_id) {
      fetchData();
    }
  }, [chats, user_id]);

  useEffect(() => {
    const fetchModel = async () => {
      try {
        const response = await axios.get(`${urlEnv}fetch-default-models`);
        if (response.data.length > 0) {
          const defaultCreator = response.data[0].creator;
          const defaultModel = response.data[0].models[0];
          setSelectedCreator(defaultCreator);
          setSelectedModel(defaultModel.model_name);
          setMaxToken(defaultModel.max_token);
          console.log("Priyansh defaultModel", defaultModel);
          console.log("Priyansh defaultCreator", defaultCreator);
        }
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };
    fetchModel();
  }, []);

  useEffect(() => {
    chats.map((chat) => {
      if (chat.id === selectedChatId) {
        updateChatMessages(
          chat.id,
          null,
          selectedCreator,
          selectedModel,
          systemInstructions,
          temperature,
          maxTokens,
          stopSequences,
          topP,
          frequencyPenalty,
          presencePenalty
        );
      }
    });
  }, [selectedModel]);

  const [userChatData, setUserChatData] = useState<[]>(null);
  const [loadingChatData, setLoadingChatData] = useState(true);

  const fetchUserChatData = async () => {
    setLoadingChatData(true);
    // console.log("calling_fetchUserChatData: ", selectedChatId);
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;

    try {
      const response = await axios.get(`${urlEnv}fetch-user-chats`, {
        params: { user_id: user_id, selectedChatId: selectedChatId },
      });
      if (response.data) {
        setUserChatData(response.data);
        // console.log("responsedata123", response.data);
        localStorage.setItem("chats", JSON.stringify(response.data));
        console.log("length:8 ", chats.length);
        setChats(response.data);
        navigate("/welcome");
      }
      setLoadingChatData(false);
    } catch (error) {
      console.error("error fetching userdata:", error);
      setLoadingChatData(false);
    }
    setLoadingChatData(false);
  };

  useEffect(() => {
    if (user_id) {
      fetchUserChatData();
    }
  }, [user_id]);

  const [buyCreditsValue, setBuyCreditsValue] = useState(() => {
    if (isNewUser || !session) {
      return "payAsYouGo";
    } else if (!isNewUser) {
      return "monthlySubscription";
    }
  });

  useEffect(() => {
    if (isNewUser || !session) {
      setBuyCreditsValue("payAsYouGo");
    } else if (!isNewUser) {
      setBuyCreditsValue("monthlySubscription");
    }
  }, [isNewUser]);

  return (
    <>
      <Root>
        <CssBaseline />
        <TopAppBar position="fixed">
          <NavigationBar data={data} loading={loading} />
          {/* Include NavigationBar inside the top app bar */}
        </TopAppBar>
        {!loadingChatData && userChatData?.length > 0 && (
          <IconContainer1 leftOpen={leftOpen}>
            {!leftOpen ? (
              <IconButton
                color="inherit"
                aria-label="open left drawer"
                onClick={handleLeftDrawerToggle}
              >
                {!leftOpen ? <MenuIcon /> : ""}
              </IconButton>
            ) : (
              ""
            )}
          </IconContainer1>
        )}

        {/* <IconContainer2 rightOpen={rightOpen}>
          {!rightOpen ? (
            <IconButton
              color="inherit"
              aria-label="open right drawer"
              onClick={handleRightDrawerToggle}
            >
              {!rightOpen ? <SettingsIcon /> : ""}
              <SettingsIcon />
            </IconButton>
          ) : (
            ""
          )}
        </IconContainer2> */}

        <ContentContainer leftOpen={leftOpen} rightOpen={rightOpen}>
          <SideDrawer variant="persistent" anchor="left" open={leftOpen}>
            <CloseButton
              handler={handleLeftDrawerToggle}
              rightORleft={"close-left"}
            />
            <Sidebar
              chats={chats}
              setChats={setChats}
              activeChatId={selectedChatId}
              startNewChat={handleNewChat}
              loadChat={handleSelectChat}
              deleteChat={deleteChat}
              isNewUser={isNewUser}
              userSubstype={userSubstype}
            // openModalPromo={isNewUser ? openModalPromo : undefined}
            // setOpenModalPromo={setOpenModalPromo}
            />
          </SideDrawer>

          <MainContent leftOpen={leftOpen} rightOpen={rightOpen}>
            <WelcomeSection onOpenSettings={handleRightDrawerToggle} />
          </MainContent>

          <SideDrawer variant="persistent" anchor="right" open={rightOpen}>
            <CloseButton
              handler={handleRightDrawerToggle}
              rightORleft={"close-right"}
            />
            <SettingsPanel
              // onSettingsChange={handleSettingsChange}
              systemInstructions={systemInstructions}
              setSystemInstructions={setSystemInstructions}
              temperature={temperature}
              setTemperature={setTemperature}
              maxTokens={maxTokens}
              setMaxTokens={setMaxTokens}
              topP={topP}
              setTopP={setTopP}
              stopSequences={stopSequences}
              setStopSequences={setStopSequences}
              frequencyPenalty={frequencyPenalty}
              setFrequencyPenalty={setFrequencyPenalty}
              presencePenalty={presencePenalty}
              setPresencePenalty={setPresencePenalty}
              disabled={isDropdownDisabled}
              startNewChat={handleNewChat}
            />
          </SideDrawer>
        </ContentContainer>
        <AppFooter />
      </Root>

      <Dialog.Root open={openModalPromo} onOpenChange={setOpenModalPromo}>
        <Dialog.Trigger asChild></Dialog.Trigger>

        <Dialog.Portal>
          <div className="sideBar-subs-panel">
            <div className="overlay"></div>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent DialogCustomWidth">
              <Dialog.Title className="DialogTitle"></Dialog.Title>
              <Dialog.Description className="DialogDescription"></Dialog.Description>

              <Tabs.Root
                defaultValue="monthlySubscription"
                value={buyCreditsValue}
                onValueChange={setBuyCreditsValue}
              >
                <div className="subscription-options">
                  <div className="option">
                    <h2>Monthly subscriptions</h2>
                    <ul>
                      <li>Cheaper credits </li>
                      <li>Credits refresh every month</li>
                      <li>
                        Includes full access to OpenAI and Anthropic models
                      </li>
                    </ul>
                  </div>
                  <div className="option">
                    <h2>Pay as you go</h2>
                    <ul>
                      <li>Lifetime credits cost more but never expire</li>
                      <li>Monthly credits cost less but will expire</li>
                      <li>
                        Includes full access to OpenAI and Anthropic models
                      </li>
                    </ul>
                  </div>
                </div>

                <Tabs.List className="tabs-list">
                  <Tabs.Trigger
                    value="monthlySubscription"
                    className={
                      buyCreditsValue === "monthlySubscription"
                        ? "tab-active"
                        : ""
                    }
                  >
                    Monthly
                  </Tabs.Trigger>
                  <Tabs.Trigger
                    value="payAsYouGo"
                    className={
                      buyCreditsValue === "payAsYouGo" ? "tab-active" : ""
                    }
                  >
                    Pay as you go
                  </Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content
                  value="monthlySubscription"
                  className="tab-content"
                  tabIndex={-1}
                >
                  <SubscriptionPanel userSubstype={userSubstype} />
                </Tabs.Content>
                <Tabs.Content
                  value="payAsYouGo"
                  className="tab-content"
                  tabIndex={-1}
                >
                  <BuyCreditsPanel isNewUser={isNewUser} />
                </Tabs.Content>
              </Tabs.Root>

              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              >
                <Dialog.Close asChild></Dialog.Close>
              </div>
              <Dialog.Close asChild>
                <button className="IconButton" aria-label="Close">
                  <img
                    src={crossicon}
                    alt="icon"
                    style={{
                      width: "20px",
                      height: "20px",

                      filter: "invert(1)",
                    }}
                  />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default WelcomeScreen;
