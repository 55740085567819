import React, { useEffect, useState } from "react";
import "src/styles.css";
import NavigationBar from "../screens/Navigationbar";
import WelcomeSection from "../screens/WelcomeSection";
import AppFooter from "../screens/Footer";
import logo from "../assets/logo.png";
import { styled } from "@mui/system";
import eyeopen from "src/assets/eye-regular.svg";
import eyeclose from "src/assets/eye-slash-regular.svg";
import { ErrorImage } from "./Login";
import axios from "axios";
import JellyOozeLoader from "./Loader";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import * as Dialog from "@radix-ui/react-dialog";
import crossicon from "src/assets/cross-2.svg";
import SubscriptionPanel, { BuyCreditsPanel } from "./SubscriptionPanel";
// import { useSession } from "./SessionProvider";

// need to change height
export const Containerdiv = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // flexDirection: "column",
  alignContent: "center",
  // height: "645px",
  // padding: "64px 10px",
  // height: "42vh",
});

export const Formdiv = styled("form")({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  padding: "10px",
  //   border: "1px solid #ccc",
  borderRadius: "5px",
  boxShadow: " 0 0 10px rgba(0, 0, 0, 0.1)",
  width: "400px",
});

export const Titlediv = styled("h2")({
  margin: "20px 0",
  textAlign: "start",
});

export const Labeldiv = styled("label")({
  margin: "2.5px 0",
  fontSize: "0.9em",
  color: "white",
  display: "flex",
});

export const Inputdiv = styled("input")({
  padding: "10px",
  borderRadius: "5px",
  border: "1px",
  backgroundColor: "#3a3a3a",
  color: "white",
  width: [
    "-webkit-fill-available",
    "-moz-available",
    "fill-available",
    "100%",
  ].join(" ; "),
  outline: "none", //remove
});

export const Linkdiv = styled("a")({
  //   fontSize: "1em",
  color: "#00cc66",
  textDecoration: "none",
  marginLeft: "auto",
  "&:hover": {
    textDecoration: "none",
  },
});

export const Buttondiv = styled("button")({
  padding: "10px",
  margin: "5px 0",
  height: "48px",
  border: "none",
  borderRadius: "5px",
  backgroundColor: "#00cc66",
  color: "white",
  fontSize: "1em",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#00b359",
  },
});

export const GoogleButtondiv = styled(Buttondiv)({
  backgroundColor: "white",
  //   height: "48px",
  color: "#444",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
});

export const Footerdiv = styled("div")({
  textAlign: "center",
  fontSize: "0.8em",
  color: "white",
  margin: "5px 0",
});

export const PasswordContainerdiv = styled("div")({
  display: "flex",
  position: "relative",
  flexDirection: "column",
  alignContent: "flex-start",
  justifyContent: "center",
  alignItems: "stretch",
});

export const PasswordInputdiv = styled(Inputdiv)({
  flex: 1,
});

export const ToggleButtondiv = styled("button")({
  // position: "absolute",
  bottom: "7px",
  right: "10px",
  background: "none",
  border: "none",
  color: "#00aaff",
  cursor: "pointer",
  width: "30px",
  height: "auto",
  alignSelf: "Center",
});

interface SignupForm {
  email: string;
  password: string;
  confirmPassword: string;
}

const Signup: React.FC = () => {
  // const userSession = useSession();
  // const { checkSession } = useSession() || {};
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const { session, setSession } = useAuth();
  const [loadingSignUp, setLoadingSignUp] = useState(false);
  const [form, setForm] = useState<SignupForm>({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState<SignupForm>({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const validateEmail = async (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      return "Email address is required";
    } else {
      // Wait for the checkemail function to complete
      console.log("calling checkemail");
      const exist = await checkemail(email);
      console.log("exist", exist);
      if (exist) {
        return "Email address already taken";
      }
    }

    if (!re.test(email)) {
      return "Invalid email address";
    }

    return undefined;
  };

  const checkemail = async (email) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "checkemail";
    console.log(email);
    console.log(urlEnv);

    const response = await axios.post(
      urlEnv,
      {
        email: email,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("response", response);
    console.log("response", response.data);
    if (!response.data) {
      return undefined;
    } else if (response.data) {
      return response.data;
    }
  };

  const validatePassword = (password: string): string => {
    if (!password) {
      return (password = "Password is required");
    }
    if (!(password.length >= 12)) {
      return "Password must be at least 12 characters";
    }
    return undefined;
  };
  const validateConfirmPassword = (confirmPassword: string): string => {
    if (confirmPassword !== form.password) {
      return "Passwords do not match";
    }

    return undefined;
  };
  const [isOpen, setIsOpen] = useState(false);
  const [userLogInId, setUserLogInId] = useState<number | null>(null);
  const [countdown, setCountdown] = useState(5);
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (!form.password) {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    } else {
      const passwordError = validatePassword(form.password);
      if (passwordError) {
        setErrors((prevErrors) => ({ ...prevErrors, password: passwordError }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
      }
    }

    if (!form.confirmPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
    } else {
      const passwordError = validateConfirmPassword(form.confirmPassword);
      if (passwordError) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: passwordError,
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
      }
    }
  }, [form.password, form.confirmPassword]);

  const newUserPromo = async (type: string, userlogInId: number) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    const response = await axios.post(`${urlEnv}create-checkout-session`, {
      product_id: process.env.REACT_APP_LIFETIME_PRICE_ID,
      user_id: userlogInId,
      type: type,
    });
    console.log("payment", response);
    window.location.href = response.data.url;
    setLoadingSignUp(false);
  };

  const [openModalPromo, setOpenModalPromo] = useState(false);
  const handleOpenChange = (isOpen) => {
    if (!isOpen) {
      return;
    }
    setOpenModalPromo(isOpen);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitted(true);
    setLoadingSignUp(true);
    setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    const emailError = await validateEmail(form.email);
    const passwordError = validatePassword(form.password);
    const confirmPasswordError = validateConfirmPassword(form.confirmPassword);

    if (emailError || passwordError || confirmPasswordError) {
      setErrors({
        email: emailError,
        password: passwordError,
        confirmPassword: confirmPasswordError,
      });
      setLoadingSignUp(false);
      setIsSubmitted(false);
    } else {
      setErrors({
        email: undefined,
        password: undefined,
        confirmPassword: undefined,
      });

      const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "signup";
      console.log("url", urlEnv);
      setOpenModalPromo(true);

      try {
        // Sign-up Request
        const response = await axios.post(urlEnv, {
          email: form.email,
          password: form.password,
          role: 1,
        }, { withCredentials: true });
        if (response.data.message && response.data.user.user_id) {
          setUserLogInId(response.data.user.user_id);
          let data = response.data.user.user_id;
          if (window.fbq) {
            window.fbq("track", "CompleteRegistration");
          }
          // Login Request
          try {
            data = await axios.post(`${process.env.REACT_APP_SERVER_ACCESS}login`, {
              email: form.email,
              password: form.password,
            },
              { withCredentials: true }
            );
            if (!data.data.user) {
              return;
            }
            if (data.data.user) {
              setOpenModalPromo(true);
              const user = data.data.user;
              setUserLogInId(user.id);
              const checkPaidSessionResponse = await axios.post(
                `${process.env.REACT_APP_SERVER_ACCESS}check-paid-session`,
                { email: form.email, user_id: user.id },
                { withCredentials: true }
              );
              user.paid_session = checkPaidSessionResponse.data ? true : false;
              setSession(data.data);
            }
          } catch (error) {
            if (error.response) {
              console.error("Error:", error.response.data.message); // Display backend error message
              alert(error.response.data.message); // You can show a custom error message to the user
            } else if (error.request) {
              console.error("No response received:", error.request);
            } else {
              console.error("Error setting up request:", error.message);
            }
          }

          const timerId = setInterval(() => {
            setCountdown((prevValue) => {
              if (prevValue === 1) {
                newUserPromo("lifetime", data.data.user.id);
                clearInterval(timerId);
                return 0;
              }
              return prevValue - 1;
            });
          }, 1000);

          return () => {
            clearInterval(timerId);
          }; // Cleanup on unmount
        }
      } catch (error) {
        if (error.response) {
          console.error("Error saving query:", error.response.data);
          alert(error.response.data.message); // Display backend error message
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      } finally {
        setIsSubmitted(false);
      }
    }
  };

  const handleDialogClose = () => {
    // navigate("/login");
  };

  return (
    <>
      <div className="sign-in-up">
        <NavigationBar />
      </div>

      <div className="content-signup-login">
        <div className="signup-login">
          <div className="welcome-section2">
            <h1>Welcome to PanelsAI</h1>&nbsp;
            <img src={logo} alt="Logo" className="welcome-logo" />
          </div>
        </div>

        <Containerdiv>
          <Formdiv onSubmit={handleSubmit}>
            <Titlediv>Create an account</Titlediv>
            <div className="flex-div-column">
              <Labeldiv htmlFor="email">Email</Labeldiv>
              <Inputdiv
                className={errors.email ? "input-error" : ""}
                type="text"
                id="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                placeholder="Enter your email"
              />
              {errors.email && (
                <label className="error">
                  <ErrorImage />
                  {errors.email}
                </label>
              )}
            </div>
            <PasswordContainerdiv>
              <Labeldiv htmlFor="password" style={{ marginTop: "12px" }}>
                Password
              </Labeldiv>
              <div
                className={`password-input ${errors.password ? "input-error" : ""
                  }`}
              >
                <Inputdiv
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                />

                <ToggleButtondiv
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  tabIndex={-1}
                >
                  <img
                    src={showPassword ? eyeopen : eyeclose}
                    alt="toggle visibility"
                  />
                </ToggleButtondiv>
              </div>
              {errors.password && (
                <label className="error">
                  <ErrorImage />
                  {errors.password}
                </label>
              )}
            </PasswordContainerdiv>
            <PasswordContainerdiv>
              <Labeldiv htmlFor="confirmPassword" style={{ marginTop: "12px" }}>
                Confirm password
              </Labeldiv>
              <div
                className={`password-input ${errors.confirmPassword ? "input-error" : ""
                  }`}
              >
                <Inputdiv
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={form.confirmPassword}
                  onChange={handleChange}
                  placeholder="Enter your password"
                />

                <ToggleButtondiv
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  tabIndex={-1}
                >
                  <img
                    src={showConfirmPassword ? eyeopen : eyeclose}
                    alt="toggle visibility"
                  />
                </ToggleButtondiv>
              </div>
              {errors.confirmPassword && (
                <label className="error">
                  <ErrorImage />
                  {errors.confirmPassword}
                </label>
              )}
            </PasswordContainerdiv>
            <Buttondiv type="submit" disabled={isSubmitted}>
              {isSubmitted ? <JellyOozeLoader /> : "Create account"}</Buttondiv>
            {/* <GoogleButtondiv type="button" disabled>
              <img
                src="https://img.icons8.com/color/16/000000/google-logo.png"
                alt="Google icon"
              />
              Continue with Google
            </GoogleButtondiv> */}
            <Footerdiv>
              Already Have An Account ?{" "}
              <Linkdiv href="/login" tabIndex={-1}>
                Log In
              </Linkdiv>
            </Footerdiv>
          </Formdiv>
        </Containerdiv>
      </div>
      <div className="login">
        <AppFooter />
      </div>
      {/* 
      <Dialog.Root
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) {
            handleDialogClose();
          }
          setIsOpen(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>
        <Dialog.Portal>
          <div className="overlay"></div>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent DialogCustomWidth-signup">
            <Dialog.Title className="DialogTitle"></Dialog.Title>
            <Dialog.Description className="DialogDescription"></Dialog.Description>

            <div className="subscription-panel">
              <h2>Sign up Succesfully</h2>
              <p className="description">
                Close this modal to login and start using PanelsAI
              </p>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              <Dialog.Close asChild></Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <img
                  src={crossicon}
                  alt="icon"
                  style={{
                    width: "20px",
                    height: "20px",

                    filter: "invert(1)",
                  }}
                />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root> */}

      <Dialog.Root open={openModalPromo} onOpenChange={handleOpenChange}>
        <Dialog.Trigger asChild></Dialog.Trigger>

        <Dialog.Portal>
          <div className="sideBar-subs-panel">
            <div className="overlay"></div>
            <Dialog.Overlay
              className="DialogOverlay"
              onClick={(e) => e.preventDefault()}
            />
            <Dialog.Content className="DialogContent DialogCustomWidth">
              <Dialog.Title className="DialogTitle"></Dialog.Title>
              <Dialog.Description className="DialogDescription"></Dialog.Description>
              <div className="subscription-options">
                <div className="option">
                  <h2>Click Below To Start Your $1 Trial</h2>
                  <ul>
                    <li>
                      This window will redirect to Stripe in {countdown} seconds
                    </li>
                    <li>Your lifetime credits will never expire</li>
                  </ul>
                </div>
              </div>
              {/* // */}
              <BuyCreditsPanel userLogInId={userLogInId} isNewUser={true} />
              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              ></div>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default Signup;
