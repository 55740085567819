import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
} from "react";

interface AppContextType {
  selectedModel: string;
  setSelectedModel: (model: string) => void;
  selectedCreator: string;
  setSelectedCreator: (creator: string) => void;
  selectedLLM: string;
  setSelectedLLM: (llm: string) => void;
  maxToken: number;
  setMaxToken: (limit: number) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);
export const AppProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedModel, setSelectedModel] = useState<string>("");
  const [selectedCreator, setSelectedCreator] = useState<string>("");
  const [maxToken, setMaxToken] = useState<number>(1000);
  const [selectedLLM, setSelectedLLM] = useState<string>(
    `${selectedCreator}:${selectedModel}`
  );
  React.useEffect(() => {
    setSelectedLLM(`${selectedCreator}:${selectedModel}`);
  }, [selectedModel, selectedCreator]);

  return (
    <AppContext.Provider
      value={{
        selectedModel,
        setSelectedModel,
        selectedCreator,
        setSelectedCreator,
        selectedLLM,
        setSelectedLLM,
        maxToken,
        setMaxToken,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};