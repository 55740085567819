// // CodeBlock.js
// import React, { useState } from 'react';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import '../CodeBlock.css';

// const CodeBlock = ({ language, code }) => {
//     const [copied, setCopied] = useState(false);

//     const handleCopy = () => {
//         setCopied(true);
//         setTimeout(() => setCopied(false), 3000);
//     };

//     return (
//         <div className="code-block">
//             <div className="code-header flex-row flex justify-between items-center">
//                 {language ? (<p>{language}</p>):(<div />)}
//             <CopyToClipboard text={code} onCopy={handleCopy}>
//                 <button className={`copy-button ${copied ? 'copied' : ''}`}></button>
//             </CopyToClipboard>
//             </div>
//             <pre>
//                 <code className={`language-${language}`}>
//                     {code}
//                 </code>
//             </pre>
//         </div>
//     );
// };

// export default CodeBlock;




// import React, { useState, useEffect, useRef } from 'react';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import Prism from 'prismjs';
// import 'prismjs/themes/prism-tomorrow.css'; // Choose your theme
// import 'prismjs/components/prism-javascript';
// import 'prismjs/components/prism-python';
// import 'prismjs/components/prism-java';
// import '../CodeBlocks.css';


// const CodeBlock = ({ language, code }) => {
//     const codeRef = useRef(null);
//     const [copied, setCopied] = useState(false);

//     useEffect(() => {
//         if (codeRef.current) {
//             Prism.highlightElement(codeRef.current);
//         }
//     }, [code, language]);

//     const handleCopy = () => {
//         setCopied(true);
//         setTimeout(() => setCopied(false), 3000);
//     };

//     return (
//         <div className="code-block">
//             <CopyToClipboard text={code} onCopy={handleCopy}>
//                 <button className={`copy-button ${copied ? 'copied' : ''}`}>
//                     {copied ? 'Copied!' : 'Copy'}
//                 </button>
//             </CopyToClipboard>
//             <pre className="code-pre">
//                 <code 
//                     ref={codeRef} 
//                     className={`language-${language}`}
//                 >
//                     {code}
//                 </code>
//             </pre>
//         </div>
//     );
// };

// export default CodeBlock;

// // import React from 'react';
// // import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// // import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';



// import React, { useState } from 'react';
// import '../CodeBlocks.css';
// const CodeBlock = ({ language, code }) => {
//   const [copied, setCopied] = useState(false);

//   // const handleCopy = () => {
//   //     setCopied(true);
//   //     setTimeout(() => setCopied(false), 3000);
//   // };

//   const copyCode = () => {
//     navigator.clipboard.writeText(code);
//     alert("Code copied to clipboard!");
//   };

//   return (
//     <div className="code-block">
//       <div className="code-header flex flex-row justify-between items-center">
//         <p>{language}</p>
//         <button
//           onClick={copyCode}
//           className="btn btn-copy flex items-center"
//         >
//           Copy Code
//         </button>
//       </div>
//       <div className="code-container">
//         <code>{code}</code>
//       </div>
//     </div>
//   );
// };

// export default CodeBlock;

import React, { useState, useEffect, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-c';
import '../CodeBlocks.css';

const CodeBlock = ({ language, code }) => {
    const codeRef = useRef(null);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (codeRef.current) {
            Prism.highlightElement(codeRef.current);
        }
    }, [code, language]);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <div className="code-block">
            <div className="code-header">
                <span>{language || 'code'}</span>
                <CopyToClipboard text={code} onCopy={handleCopy}>
                    <button className={`copy-btn ${copied ? 'copied' : ''}`} aria-label="Copy code">
                        {copied ? (
                            <svg className="check-icon" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
                            </svg>
                        ) : (
                            <svg className="copy-icon" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
                            </svg>
                        )}
                    </button>
                </CopyToClipboard>
            </div>
            <pre className="code-pre">
                <code
                    ref={codeRef}
                    className={`language-${language}`}
                >
                    {code}
                </code>
            </pre>
        </div>
    );
};

export default CodeBlock;