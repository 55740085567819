import React, { useEffect, useState } from "react";
import "../TypingAnimations.css";
import logo192 from "../assets/logo192.png";
import ReactMarkdown from "react-markdown";
import CodeBlock from "./CodeBlock";

const TypingEffect: React.FC<{
  text: string;
  onTypingComplete: () => void;
}> = ({ text, onTypingComplete }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);

  useEffect(() => {
    if (typingIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText((prev) => prev + text[typingIndex]);
        setTypingIndex((prev) => prev + 1);
      }, 10);
      return () => clearTimeout(timer);
    } else {
      onTypingComplete();
    }
  }, [typingIndex, text, onTypingComplete]);

  return (
    <div className="typing-container">
      <ReactMarkdown
        components={{
          code({ node, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return match ? (
              <CodeBlock
                language={match[1]}
                code={String(children).replace(/\n$/, '')}
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          }
        }}
      >
        {displayedText}
      </ReactMarkdown>
      {typingIndex < 0 && (
        <svg className="svg-cursor" viewBox="0 0 16 16">
          <image href={logo192} className="svg-image" />
        </svg>
      )}
    </div>
  );
};

export default TypingEffect;