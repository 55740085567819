import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "src/styles.css";
import NavigationBar from "../screens/Navigationbar";
import WelcomeSection from "../screens/WelcomeSection";
import AppFooter from "../screens/Footer";
import logo from "../assets/logo.png";
import { styled } from "@mui/system";
import JellyOozeLoader from "./Loader";
import eyeopen from "src/assets/eye-regular.svg";
import eyeclose from "src/assets/eye-slash-regular.svg";
import * as Tabs from "@radix-ui/react-tabs";
import {
  Containerdiv,
  Formdiv,
  Titlediv,
  Labeldiv,
  Inputdiv,
  Linkdiv,
  Buttondiv,
  Footerdiv,
  PasswordContainerdiv,
  ToggleButtondiv,
} from "./Signup";
import errorimage from "src/assets/circle-exclamation-solid.svg";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "./AuthContext";
import * as Dialog from "@radix-ui/react-dialog";
import crossicon from "src/assets/cross-2.svg";
import { BuyCreditsPanel } from "./SubscriptionPanel";

export const ErrorImage = () => {
  return <img src={errorimage} alt="error" className="error-image" />;
};

interface LoginForm {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { session, setSession } = useAuth();
  console.log("Login session", session);
  const user_id = session?.user?.id;
  console.log("Login user_id", user_id);
  let canceled = searchParams.get("canceled");

  const handleLogout = async () => {
    try {
      console.log("Logging out...");

      // Request server to destroy session
      await axios.post(
        `${process.env.REACT_APP_SERVER_ACCESS}logout`,
        {},
        { withCredentials: true }
      );

      // Clear session state
      setSession(null);

      // Remove all stored user data
      localStorage.removeItem("isSub");
      localStorage.removeItem("chats");
      localStorage.removeItem("userToken");
      sessionStorage.removeItem("user_id");
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const [isSubmited, setIsSubmited] = useState(false);
  const [loginLoading, setLoginLoading] = useState(true);

  useEffect(() => {
    const checkIfNewUserAndSubscriptionType = async () => {
      console.log("Checking if new user and subscription type...");
      if (!user_id) return;

      setLoginLoading(true);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_ACCESS}checkIfNewUserAndSubscriptionType`,
          { params: { user_id: user_id } }
        );
        console.log('Server response:', response.data);
        if (response?.data?.success) {
          console.log("Logging out user...");
          handleLogout();
        } else if (response?.data?.success === false) {
          console.log("Navigating to welcome page...");
          navigate("/welcome");
        } else {
          console.log("User role type set successfully");
        }
      } catch (err) {
        console.error("Error fetching checkIfNewUser:", err.message);
      } finally {
        setLoginLoading(false);
      }
    };

    if (user_id && !openModalPromo) {
      checkIfNewUserAndSubscriptionType();
    }
  }, [user_id]);


  const newUserPromo = async (type: string, userlogInId: number) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;

    console.log("user_id", userlogInId);
    console.log("userLogInId", userLogInId);
    console.log("priceID", process.env.REACT_APP_LIFETIME_PRICE_ID);

    const response = await axios.post(`${urlEnv}create-checkout-session`, {
      product_id: process.env.REACT_APP_LIFETIME_PRICE_ID,
      user_id: user_id || userLogInId,
      type: type,
    });
    console.log(response);
    window.location.href = response.data.url;
  };

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [isOpen2, setIsOpen2] = useState(false);
  const openDialog2 = () => setIsOpen2(true);
  const closeDialog2 = () => setIsOpen2(false);
  let type = searchParams.get("type");
  useEffect(() => {
    if (type !== null) {
      switch (type) {
        case "payment":
          openDialog2();
          const newSearchParams2 = new URLSearchParams(searchParams);
          newSearchParams2.delete("type");
          setSearchParams(newSearchParams2);
          type = "";
          break;
        default:
          break;
      }
    }
  }, []);

  const [form, setForm] = useState<LoginForm>({ email: "", password: "" });
  const [errors, setErrors] = useState<LoginForm>({ email: "", password: "" });
  const [loginErrors, setLoginErrors] = useState("");

  const validateEmail = (email: string): string => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      return "Email address is required";
    }
    if (!re.test(email)) {
      return "Invalid email address";
    }

    return undefined;
  };

  const validatePassword = (password: string): string => {
    if (!password) {
      return "Password is required";
    }

    return undefined;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const [openModalPromo, setOpenModalPromo] = useState(false);
  const handleOpenChange = (isOpen) => {
    if (!isOpen) {
      // Do nothing when an attempt is made to close the modal
      return;
    }
    setOpenModalPromo(isOpen);
  };

  const [countdown, setCountdown] = useState(5);
  const validateCredentials = async (email: string) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "checkcredentials";
    try {
      const response = await axios.post(urlEnv, {
        email: email,
      });
      console.log("response ", response);
      if (response?.data?.user_id) {
        setUserLogInId(response?.data?.user_id);
      }

      if (response?.data?.role === 1) {
        setOpenModalPromo(true);
        const timerId = setInterval(() => {
          setCountdown((prevValue) => {
            if (prevValue === 1) {
              newUserPromo("lifetime", response.data.user_id);
              clearInterval(timerId);
              return 0;
            }
            return prevValue - 1;
          });
        }, 1000);

        return;
      }
      if (!response.data) {
        return "Invalid credentials";
      }
      return undefined;
    } catch (error) {
      if (error.response) {
        console.error("Error saving query:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    }
  };
  let [tof, setTof] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);

  const [userLogInId, setUserLogInId] = useState<number | null>(null);

  const openDialog1 = () => setIsOpen1(true);
  const closeDialog1 = () => setIsOpen1(false);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmited(true);
    const emailError = validateEmail(form.email);
    const passwordError = validatePassword(form.password);
    const credentialsError = await validateCredentials(form.email);

    if (credentialsError) {
      setErrors({ email: credentialsError, password: credentialsError });
      setTof(true);
      setIsSubmited(false);
    } else if (emailError || passwordError) {
      setErrors({ email: emailError, password: passwordError });
      setIsSubmited(false);
    } else {
      setErrors({ email: undefined, password: undefined });

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_ACCESS}login`,
          {
            email: form.email,
            password: form.password,
          },
          { withCredentials: true }
        );
        if (response.data.user) {
          const user = response.data;
          const checkPaidSessionResponse = await axios.post(
            `${process.env.REACT_APP_SERVER_ACCESS}check-paid-session`,
            { email: form.email, user_id: user.user_id },
            { withCredentials: true }
          );
          user.paid_session = checkPaidSessionResponse.data ? true : false;
          setSession(user);
          console.log("Priyansh user", user);
        } else {
          setLoginErrors("Invalid credentials. Please try again.");
          openDialog1();
        }
      } catch (error) {
        if (error.response) {
          console.error("Error response:", error.response.data);
          setLoginErrors(error.response.data.message);
          openDialog1();
        } else if (error.request) {
          console.error("No response received:", error.request);
          setLoginErrors("No response received from the server.");
          openDialog1();
        } else {
          console.error("Error setting up request:", error.message);
          setLoginErrors(error.message);
          openDialog1();
        }
      }
      finally {
        setIsSubmited(false);
      }
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const handleForgotPassword = async () => {
    const emailError = validateEmail(form.email);
    console.log("form.email", form.email);
    const email = form.email;

    if (emailError) {
      setErrors({ email: emailError, password: undefined });
    } else {
      setErrors({ email: undefined, password: undefined });

      const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "reset-password";

      const response = await axios.post(urlEnv, { email: email });
      console.log(response.data);
      if (response.data.message) {
        openDialog();
      } else if (!response.data) {
        alert(
          " error: Email rate limit exceeded / email confirmation already sent"
        );
      }
    }
  };
  const handleDialogClose = () => {
    // navigate("/login");
  };

  return (
    <>
      <div className="sign-in-up">
        <NavigationBar />
      </div>

      <div className="content-signup-login">
        <div className="signup-login">
          <div className="welcome-section2">
            <h1>Welcome to PanelsAI</h1>&nbsp;
            <img src={logo} alt="Logo" className="welcome-logo" />
          </div>
        </div>

        <Containerdiv>
          <Formdiv onSubmit={handleSubmit}>
            <Titlediv>Login to your account</Titlediv>
            <div className="flex-div-column">
              {errors.email && errors.password && (
                <label className="error">
                  <ErrorImage />
                  {errors.email}
                </label>
              )}
              {loginErrors && (
                <label className="error">
                  <ErrorImage />
                  {loginErrors}
                </label>
              )}

              <Labeldiv htmlFor="email">Email</Labeldiv>
              <Inputdiv
                className={
                  errors.email === "Invalid credentials" || loginErrors
                    ? "input-error"
                    : ""
                }
                type="text"
                id="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                placeholder="Enter your email"
              />
              {!tof && errors.email && (
                <label className="error">
                  <ErrorImage />
                  {errors.email}
                </label>
              )}
            </div>
            <PasswordContainerdiv>
              <Labeldiv htmlFor="password" style={{ marginTop: "12px" }}>
                Password
                <Linkdiv
                  href="#"
                  onClick={handleForgotPassword}
                  // onKeyDown={(e) => {
                  //   handleKeyDown(undefined);
                  // }}
                  tabIndex={-1}
                >
                  Forgot ?
                </Linkdiv>
              </Labeldiv>
              <div
                className={`password-input ${errors.password === "Invalid credentials" || loginErrors
                  ? "input-error"
                  : ""
                  }`}
              >
                <Inputdiv
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                />

                <ToggleButtondiv
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  tabIndex={-1}
                >
                  <img
                    src={showPassword ? eyeopen : eyeclose}
                    alt="toggle visibility"
                  />
                </ToggleButtondiv>
              </div>
              {!tof && errors.password && (
                <label className="error">
                  <ErrorImage />
                  {errors.password}
                </label>
              )}
            </PasswordContainerdiv>
            <Buttondiv type="submit" disabled={isSubmited}>
              {isSubmited ? <JellyOozeLoader /> : "Log in now"}
            </Buttondiv>
            {/* <button onClick={test}>test login</button> */}
            <Footerdiv>
              Don't Have An Account ?{" "}
              <Linkdiv href="/signup" tabIndex={-1}>
                Sign up
              </Linkdiv>
            </Footerdiv>
          </Formdiv>
        </Containerdiv>
      </div>
      <div className="login">
        <AppFooter />
      </div>
      <Dialog.Root
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) {
            handleDialogClose();
          }
          setIsOpen(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>
        <Dialog.Portal>
          <div className="overlay"></div>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent DialogCustomWidth-signup">
            <Dialog.Title className="DialogTitle"></Dialog.Title>
            <Dialog.Description className="DialogDescription"></Dialog.Description>

            <div className="subscription-panel">
              <h2>Password reset link sent</h2>
              <p className="description">
                Please check your email to reset your password.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              <Dialog.Close asChild></Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <img
                  src={crossicon}
                  alt="icon"
                  style={{
                    width: "20px",
                    height: "20px",

                    filter: "invert(1)",
                  }}
                />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <Dialog.Root
        open={isOpen1}
        onOpenChange={(open) => {
          if (!open) {
            handleDialogClose();
          }
          setIsOpen1(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>
        <Dialog.Portal>
          <div className="overlay"></div>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent DialogCustomWidth-signup">
            <Dialog.Title className="DialogTitle"></Dialog.Title>
            <Dialog.Description className="DialogDescription"></Dialog.Description>

            <div className="subscription-panel">
              <h2>Email not verified</h2>
              <p className="description">
                Please check your email to verify to start using PanelsAI.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              <Dialog.Close asChild></Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <img
                  src={crossicon}
                  alt="icon"
                  style={{
                    width: "20px",
                    height: "20px",

                    filter: "invert(1)",
                  }}
                />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <Dialog.Root open={openModalPromo} onOpenChange={handleOpenChange}>
        <Dialog.Trigger asChild></Dialog.Trigger>

        <Dialog.Portal>
          <div className="sideBar-subs-panel">
            <div className="overlay"></div>
            <Dialog.Overlay
              className="DialogOverlay"
              onClick={(e) => e.preventDefault()}
            />
            <Dialog.Content className="DialogContent DialogCustomWidth">
              <Dialog.Title className="DialogTitle"></Dialog.Title>
              <Dialog.Description className="DialogDescription"></Dialog.Description>

              <div className="subscription-options">
                <div className="option">
                  <h2>Click Below To Start Your $1 Trial</h2>
                  <ul>
                    <li>
                      This window will redirect to Stripe in {countdown} seconds
                    </li>
                    <li>Your lifetime credits will never expire</li>
                  </ul>
                </div>
              </div>

              <BuyCreditsPanel userLogInId={userLogInId} isNewUser={true} />

              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              ></div>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>

      <Dialog.Root
        open={isOpen2}
        onOpenChange={(open) => {
          setIsOpen2(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>

        <Dialog.Portal>
          <div className="sideBar-subs-panel">
            <div className="overlay"></div>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent DialogCustomWidth-signup">
              <Dialog.Title className="DialogTitle"></Dialog.Title>
              <Dialog.Description className="DialogDescription"></Dialog.Description>
              <div className="subscription-panel">
                <h2>Succesfully bought credits</h2>
                <span>You may log in now</span>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              >
                <Dialog.Close asChild></Dialog.Close>
              </div>
              <Dialog.Close asChild>
                <button className="IconButton" aria-label="Close">
                  <img
                    src={crossicon}
                    alt="icon"
                    style={{
                      width: "20px",
                      height: "20px",

                      filter: "invert(1)",
                    }}
                  />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

export default Login;
