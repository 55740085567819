// src/components/Sidebar.tsx
import React, { useEffect, useRef, useState } from "react";
import Button from "./Button";
import { ChatHistory, Message } from "./SendMessage";
// import 'src/chatwindow.css';
import { PenLine, EllipsisVertical, Trash } from "lucide-react";
import * as Dropdown from '@radix-ui/react-dropdown-menu';
import writingImage from "src/assets/writing.png";
import settingImage from "src/assets/settings.png";
import logoutImage from "src/assets/logout.png";
import starImage from "src/assets/star.png";
import trashcan from "src/assets/trash-can.svg";
import { useLocation, useNavigate } from "react-router-dom";
import * as Popover from "@radix-ui/react-popover";
import "src/morebutton.css";
// import { useSession } from "./SessionProvider";
import axios from "axios";
import * as Dialog from "@radix-ui/react-dialog";
import SubscriptionPanel, {
  BuyCreditsPanel,
} from "../components/SubscriptionPanel";
import crossicon from "src/assets/cross-2.svg";
import "src/modal.css";
import { useAuth } from "./AuthContext";
import SubsPanel from "../components/SubscriptionPanel";
import { useAppContext } from "./LLMContext";
import MyBilling from "../components/MyBilling";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const urlEnv = process.env.REACT_APP_SERVER_ACCESS;

export function Image({ source, alternate }) {
  return <img className="image-size" src={source} alt={alternate} />;
}

export interface SidebarProps {
  chats: {
    // thread_id: number;
    id: number;
    title: string;
  }[];
  setChats: React.Dispatch<React.SetStateAction<ChatHistory[]>>;
  activeChatId: number | null;
  startNewChat: () => void;
  loadChat: (id: number) => void;
  deleteChat: (id: number) => void;

  isNewUser?: boolean;
  setIsNewUser?: React.Dispatch<React.SetStateAction<boolean>>;
  userSubstype: number;

  openModalPromo?: boolean;
  setOpenModalPromo?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Chat {
  id: number;
  title: string;
}

const Sidebar: React.FC<SidebarProps> = ({
  chats, // = [{ id: 1, title: "Chat 1" }],
  setChats,
  activeChatId,
  startNewChat,
  loadChat,
  deleteChat,
  isNewUser,
  setIsNewUser,
  userSubstype,
  openModalPromo,
  setOpenModalPromo,
}) => {
  const {
    selectedModel,
    setSelectedModel,
    selectedCreator,
    setSelectedCreator,
    selectedLLM,
    setSelectedLLM,
  } = useAppContext();
  const { session, setSession } = useAuth();
  const user_id = session?.user?.id;
  console.log("SideBar user_id", user_id);
  const [editingChatId, setEditingChatId] = useState<number | null>(null);
  const [newTitle, setNewTitle] = useState("");
  const [isModalOpen1, setModalOpen1] = useState(false);
  const [chatToDelete, setChatToDelete] = useState<number>(null);
  const location = useLocation();
  const [selectedChatId, setSelectedChatId] = useState<number | null>(
    (location.state as { selectedChatId?: number })?.selectedChatId || null
  );

  useEffect(() => {

    if (selectedChatId) {
      loadChat(selectedChatId);
      setSelectedChatId(null);
    }
  }, [selectedChatId]);


  const handleLogout = async () => {
    try {
      console.log("Logging out...");

      await axios.post(
        `${process.env.REACT_APP_SERVER_ACCESS}logout`,
        {},
        { withCredentials: true }
      );

      // Clear session state
      setSession(null);

      // Remove all stored user data
      localStorage.removeItem("isSub");
      localStorage.removeItem("chats");
      localStorage.removeItem("userToken");
      sessionStorage.removeItem("user_id");

      // Redirect to login
      window.location.href = "/login";
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleTitleUpdate = async (chatId: number, newTitle: string) => {
    if (!newTitle.trim()) return;

    try {
      console.log("Updating Chat Title:", chatId, newTitle);
      console.log("Previous Chatss: ", chats);
      // Update chat title in local state
      const updatedChats = chats.map((chat) =>
        chat.id === chatId ? { ...chat, title: newTitle } : chat
      ) as ChatHistory[];

      setChats(updatedChats);

      console.log("Updated Chats:", updatedChats);
      // Save updated chats to localStorage
      localStorage.setItem("chats", JSON.stringify(updatedChats));

      // API call to persist changes
      await axios.post(`${process.env.REACT_APP_SERVER_ACCESS}update-chat-title`, {
        chat_id: chatId,
        new_title: newTitle,
      });

    } catch (error) {
      console.error("Error updating chat title:", error);
    } finally {
      setEditingChatId(null);
      setNewTitle("");
    }
  };

  const hasCreatedInitialChat = useRef(false);

  useEffect(() => {
    if (chats.length > 0 && !hasCreatedInitialChat.current) {
      // Automatically load the last chat or whichever chat you want
      loadChat(chats[chats.length - 1].id);
      hasCreatedInitialChat.current = true;
      //console.log("loaded");
    } else if (selectedChatId) {
      loadChat(selectedChatId);
    }
  }, [chats, activeChatId, loadChat, selectedChatId]);

  const inputRef = useRef(null);
  const inputData = () => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 1);
  };

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${urlEnv}fetch-credits-history`, {
        user_id: user_id,
      });
      setData(response.data);
    } catch (err) {
      setError(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    // Options for formatting the date
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Return the formatted date
    return date.toLocaleDateString("en-US", options);
  };

  const [delay, setDelay] = useState(true);

  useEffect(() => {
    if (chats) {
      const timeoutDelay = setTimeout(() => {
        setDelay(false);
      }, 1500);
      return () => clearTimeout(timeoutDelay);
    }
  }, [chats]);

  return (
    <div className="sidebar">
      <button className="new-chat-button left-margin-t" onClick={startNewChat}>
        <Image source={writingImage} alternate={"writingImage"} />
      </button>
      <div className="chat-history">
        {!delay ? (
          chats
            .slice()
            .sort((a, b) => a.id - b.id)
            .map((chat) => (
              console.log("chats", chat),
              <div
                key={chat.id}
                className={`sidebar-chats ${chat.id === activeChatId ? "active" : ""
                  }`}
              >
                <div
                  className={`chat-history-item ${chat.id === activeChatId ? "active" : ""
                    }`}
                  onClick={() => {
                    if (editingChatId === null) {
                      loadChat(chat.id);
                    }
                  }}
                >
                  {editingChatId === chat.id ? (
                    <input
                      ref={inputRef} // Assign input reference correctly
                      type="text"
                      value={newTitle}
                      onChange={(e) => setNewTitle(e.target.value)}
                      onBlur={() => handleTitleUpdate(chat.id, newTitle)}
                      onKeyDown={(e) => e.key === "Enter" && handleTitleUpdate(chat.id, newTitle)}
                      autoFocus
                      className="title-edit-input"
                      maxLength={50}
                    />
                  ) : (
                    <span className="chat-title-text" title={chat.title}>
                      {chat.title.length > 20 ? chat.title.substring(0, 20) + "..." : chat.title || "Untitled Chat"}
                    </span>

                  )}
                </div>

                <Dropdown.Root>
                  <Dropdown.Trigger className="dropdown-trigger" asChild>
                    <button
                      className="menu-trigger-button"
                      aria-label="Chat options"
                    >

                      <EllipsisVertical size={25} />
                    </button>
                  </Dropdown.Trigger>

                  <Dropdown.Content className="dropdown-content" align="start">
                    <Dropdown.Item
                      className="dropdown-item"
                      onClick={(e) => {
                        inputData()//stop the event from bubbling up
                        setEditingChatId(chat.id);
                        setNewTitle(chat.title);
                      }}
                    >
                      <PenLine size={12} className="icon" />
                      Rename
                    </Dropdown.Item>
                    {/* <Dropdown.Separator /> */}
                    <Dropdown.Item
                      className="dropdown-item danger flex items-center gap-2"
                      onClick={() => {
                        // e.stopPropagation();//stop the event from bubbling up
                        setChatToDelete(chat.id);
                        setModalOpen1(true);
                      }}
                    >

                      <Trash size={13} className="icon" />
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown.Root>
              </div>
            ))
        ) : (
          <>
            {Array.from({ length: 8 }).map((_, index) => (
              <div
                key={index}
                className={`sidebar-chats active chat-tabLoader`}
              >
                <div className={`chat-history-item active`}></div>
              </div>
            ))}
          </>
        )}
      </div>

      <Dialog.Root open={isModalOpen1} onOpenChange={setModalOpen1}>
        <Dialog.Portal>
          <div className="overlay"></div>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent DialogCustomWidth-signup">
            <div className="subscription-panel">
              <h2>This will delete the chat conversation</h2>
              <p className="description">Are you sure you want to delete?</p>
            </div>
            <div className="modal-confirmation">
              <Dialog.Close asChild>
                <button className="no-button" aria-label="Close">No</button>
              </Dialog.Close>
              <button
                onClick={() => {
                  if (chatToDelete) {
                    deleteChat(chatToDelete);
                    setModalOpen1(false);
                  }
                }}
                className="yes-button"
              >
                Yes
              </button>
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <img src={crossicon} alt="Close" style={{ width: "20px", height: "20px", filter: "invert(1)" }} />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      {session ? (
        <Dialog.Root open={openModalPromo} onOpenChange={setOpenModalPromo}>
          <Dialog.Trigger asChild>
            <button
              className="menu-footer-content green-color"
              onClick={fetchData}
            >
              <Image source={starImage} alternate={"starImage"} />
              Billing
            </button>
          </Dialog.Trigger>

          <Dialog.Portal>
            <div className="sideBar-subs-panel">
              <div className="overlay"></div>
              <Dialog.Overlay className="DialogOverlay" />
              <Dialog.Content className="DialogContent DialogCustomWidth">
                <Dialog.Title className="DialogTitle"></Dialog.Title>
                <Dialog.Description className="DialogDescription"></Dialog.Description>
                <Elements stripe={stripePromise}>
                  <MyBilling
                    data={data}
                    loading={loading}
                    userSubstype={userSubstype}
                  />
                </Elements>

                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    justifyContent: "flex-end",
                  }}
                >
                  <Dialog.Close asChild></Dialog.Close>
                </div>
                <Dialog.Close asChild>
                  <button className="IconButton" aria-label="Close">
                    <img
                      src={crossicon}
                      alt="icon"
                      style={{
                        width: "20px",
                        height: "20px",

                        filter: "invert(1)",
                      }}
                    />
                  </button>
                </Dialog.Close>
              </Dialog.Content>
            </div>
          </Dialog.Portal>
        </Dialog.Root>
      ) : (
        <Dialog.Root open={openModalPromo} onOpenChange={setOpenModalPromo}>
          <Dialog.Trigger asChild></Dialog.Trigger>

          <Dialog.Portal>
            <div className="sideBar-subs-panel">
              <div className="overlay"></div>
              <Dialog.Overlay className="DialogOverlay" />
              <Dialog.Content className="DialogContent DialogCustomWidth">
                <Dialog.Title className="DialogTitle"></Dialog.Title>
                <Dialog.Description className="DialogDescription"></Dialog.Description>

                <MyBilling
                  data={data}
                  loading={loading}
                  isNewUser={isNewUser}
                  userSubstype={userSubstype}
                />

                <div
                  style={{
                    display: "flex",
                    marginTop: 25,
                    justifyContent: "flex-end",
                  }}
                >
                  <Dialog.Close asChild></Dialog.Close>
                </div>
                <Dialog.Close asChild>
                  <button className="IconButton" aria-label="Close">
                    <img
                      src={crossicon}
                      alt="icon"
                      style={{
                        width: "20px",
                        height: "20px",

                        filter: "invert(1)",
                      }}
                    />
                  </button>
                </Dialog.Close>
              </Dialog.Content>
            </div>
          </Dialog.Portal>
        </Dialog.Root>
      )}
      {session && (
        <button className="menu-footer-content" onClick={handleLogout}>
          <Image source={logoutImage} alternate={"logoutImage"} />
          Logout
        </button>
      )}
    </div>
  );
};

export default Sidebar;