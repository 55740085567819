import React, { useEffect, useState } from "react";
import "../styles.css";
import starImage from "src/assets/star.png";
import { Image } from "../components/SideBar";
import SettingsPanel from "../components/SettingsPanel";
import "src/modal.css";
import crossicon from "src/assets/cross-2.svg";
import * as Dialog from "@radix-ui/react-dialog";
import SubscriptionPanel, {
  BuyCreditsPanel,
} from "../components/SubscriptionPanel";
import { useAuth } from "../components/AuthContext";
import axios from "axios";
import * as Tabs from "@radix-ui/react-tabs";

interface FooterProps {
  userSubstype?: number;
  isNewUser?: boolean;
}
const AppFooter: React.FC = () => {
  const { session } = useAuth();
  const user_id = session?.user?.id;
  const [userSubstype, setUserSubstype] = useState<number>(0);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);

  useEffect(() => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;

    const checkIfNewUserAndSubscriptionType = async () => {
      try {
        const response = await axios.get(
          `${urlEnv}checkIfNewUserAndSubscriptionType`,
          {
            params: { user_id: user_id },
          }
        );
        // console.log("checkifnewuser", response.data.subscription);

        if (response?.data?.subscription) {
          setUserSubstype(response.data.subscription);
          // console.log("set user subs type");
        } else {
          //setUserSubstype("");
        }

        if (response?.data?.success) {
          setIsNewUser(true);

          // console.log("set user role type");
        } else {
          setIsNewUser(false);
          // console.log("yayaya");
        }
      } catch (err) {
        console.error("error fething checkIfNewUser:", err.message);
      }
    };
    checkIfNewUserAndSubscriptionType();
  }, [user_id]);

  const [buyCreditsValue, setBuyCreditsValue] = useState(() => {
    if (isNewUser || !session) {
      return "payAsYouGo";
    } else if (!isNewUser) {
      return "monthlySubscription";
    }
  });

  return (
    <div className="footer">
      {/*
<a type="button" className="welcome-advance-settings">
<Image source={settingImage} alternate={"settingImage"} />
Advance Settings
</a>
<hr /> */}
      <Dialog.Root>
        {/* {userSubstype !== "monthly" ? (
          <Dialog.Trigger asChild>
            <a href="#" className="green-color ">
              <Image source={starImage} alternate={"starImage"} />
              Upgrade to Premium version
            </a>
          </Dialog.Trigger>
        ) : (
          <a href="#" className="green-color ">
            <Image source={starImage} alternate={"starImage"} />
            Subscribed to Premium version
          </a>
        )} */}

        <Dialog.Portal>
          <div className="sideBar-subs-panel">
            <div className="overlay"></div>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent DialogCustomWidth">
              <Dialog.Title className="DialogTitle"></Dialog.Title>
              <Dialog.Description className="DialogDescription"></Dialog.Description>

              <Tabs.Root
                defaultValue="monthlySubscription"
                value={buyCreditsValue}
                onValueChange={setBuyCreditsValue}
              >
                <div className="subscription-options">
                  <div className="option">
                    <h2>Monthly subscriptions</h2>
                    <ul>
                      <li>Cheaper credits </li>
                      <li>Credits refresh every month</li>
                      <li>
                        Includes full access to OpenAI and Anthropic models
                      </li>
                    </ul>
                  </div>
                  <div className="option">
                    <h2>Pay as you go</h2>
                    <ul>
                      <li>Lifetime credits cost more but never expire</li>
                      <li>Monthly credits cost less but will expire</li>
                      <li>
                        Includes full access to OpenAI and Anthropic models
                      </li>
                    </ul>
                  </div>
                </div>

                <Tabs.List className="tabs-list">
                  <Tabs.Trigger
                    value="monthlySubscription"
                    className={
                      buyCreditsValue === "monthlySubscription"
                        ? "tab-active"
                        : ""
                    }
                  >
                    Monthly
                  </Tabs.Trigger>
                  <Tabs.Trigger
                    value="payAsYouGo"
                    className={
                      buyCreditsValue === "payAsYouGo" ? "tab-active" : ""
                    }
                  >
                    Pay as you go
                  </Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content
                  value="monthlySubscription"
                  className="tab-content"
                  tabIndex={-1}
                >
                  <SubscriptionPanel userSubstype={userSubstype} />
                </Tabs.Content>
                <Tabs.Content
                  value="payAsYouGo"
                  className="tab-content"
                  tabIndex={-1}
                >
                  <BuyCreditsPanel isNewUser={isNewUser} />
                </Tabs.Content>
              </Tabs.Root>

              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              >
                <Dialog.Close asChild></Dialog.Close>
              </div>
              <Dialog.Close asChild>
                <button className="IconButton" aria-label="Close">
                  <img
                    src={crossicon}
                    alt="icon"
                    style={{
                      width: "20px",
                      height: "20px",

                      filter: "invert(1)",
                    }}
                  />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </div>
        </Dialog.Portal>
      </Dialog.Root>

      {/* <a href="#">Privacy Policy</a>
      <a href="#">Terms & Conditions</a>
      <a href="#">Affiliate Programs</a>
      <a href="#">API</a> */}
    </div>
  );
};

export default AppFooter;
