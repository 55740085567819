import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState("loading");

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_ACCESS}check-session`,
          { withCredentials: true }
        );
        if (response.data.isLoggedIn) {
          setSession(response.data.user);
        } else {
          setSession(null);
        }
      } catch (error) {
        console.error("Error fetching session:", error);
        setSession(null);
      }
    };

    fetchSession();
  }, []);

  return (
    <AuthContext.Provider value={{ session, setSession }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);