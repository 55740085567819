import React, { useState, useEffect, useRef } from "react";
import "../styles.css";
import logo from "../assets/logo.png";
import profpic from "../assets/image.jpeg";
import Dropdownllm from "./Llm";
import InputText from "./SendMessage";
import { InputTextProps } from "./SendMessage";
import CodeBlock from './CodeBlock';
import TypingEffect from "./TypingEffect";
import { useAppContext } from "./LLMContext";
import logo192 from "../assets/logo192.png";
import SettingsIcon from "@mui/icons-material/Settings";
import ReactMarkdown from "react-markdown";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const MarkdownRenderer: React.FC<{ content: string }> = ({ content }) => {
  return <ReactMarkdown>{content}</ReactMarkdown>;
};

const extractCodeBlocks = (message) => {
  const codeBlockCount = (message.match(/```/g) || []).length;
  if (codeBlockCount % 2 !== 0) {
    message += '```';
  }
  const codeBlockRegex = /```(\w+)?\n([\s\S]*?)```/g;
  const parts = [];
  let lastIndex = 0;
  let match;
  while ((match = codeBlockRegex.exec(message)) !== null) {
    if (lastIndex < match.index) {
      parts.push({ type: 'text', content: message.slice(lastIndex, match.index) });
    }
    parts.push({
      type: 'code',
      language: match[1] || 'plaintext',
      code: match[2],
    });

    lastIndex = codeBlockRegex.lastIndex;
  }
  if (lastIndex < message.length) {
    parts.push({ type: 'text', content: message.slice(lastIndex) });
  }
  return parts;
};

const ChatComponent: React.FC<InputTextProps> = ({
  selectedChatId,
  chats,
  setChats,
  updateChatMessages,
  sendMessageButtonRef,
  onChatLoaded,
  onOpenSettings,
  systemInstructions,
  setSystemInstructions,
  temperature,
  setTemperature,
  maxTokens,
  setMaxTokens,
  topP,
  setTopP,
  stopSequences,
  setStopSequences,
  frequencyPenalty,
  setFrequencyPenalty,
  presencePenalty,
  setPresencePenalty,
  loadingChatData,
}) => {
  useEffect(() => {
    onChatLoaded();
  }, [onChatLoaded]);

  const {
    selectedModel,
    setSelectedModel,
    selectedCreator,
    setSelectedCreator,
    selectedLLM,
    setSelectedLLM,
    maxToken,
    setMaxToken,
  } = useAppContext();

  const [inputText, setInputText] = useState<string>(() => {
    const savedInputText = sessionStorage.getItem("inputText");
    return savedInputText || "";
  });

  useEffect(() => {
    sessionStorage.setItem("inputText", inputText);
  }, [inputText]);

  const handleTypingComplete = (messageId: number) => {
    const chat = chats.find((chat) => chat.id === selectedChatId);
    if (chat) {
      const updatedMessages = chat.messages.map((msg) =>
        msg.id === messageId ? { ...msg, isNew: false } : msg
      );
      // update chat thread every send message
      updateChatMessages(
        chat.id,
        updatedMessages,
        selectedCreator,
        selectedModel,
        systemInstructions,
        temperature,
        maxTokens,
        stopSequences,
        topP,
        frequencyPenalty,
        presencePenalty
      );
    }
  };

  const chatContainerRef = useRef(null);
  const chatEndRef = useRef(null);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [updateChatMessages, onChatLoaded, updateChatMessages, chats]); // Auto-scroll when messages change

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    const observer = new MutationObserver(() => {
      if (isAutoScrollEnabled) {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    });

    // Observe changes in the chat container's child elements
    if (chatContainer) {
      observer.observe(chatContainer, { childList: true, subtree: true });
    }

    // Cleanup observer on unmount
    return () => observer.disconnect();
  }, [chats, updateChatMessages, onChatLoaded, isAutoScrollEnabled]);

  const handleScroll = () => {
    const chatContainer = chatContainerRef.current;
    const { scrollTop, scrollHeight, clientHeight } = chatContainer;

    // If the user scrolls up, disable auto-scroll
    if (scrollTop + clientHeight < scrollHeight) {
      setIsAutoScrollEnabled(false);
    }
    // If the user is at the bottom, enable auto-scroll
    else if (scrollTop + clientHeight === scrollHeight) {
      setIsAutoScrollEnabled(true);
    }
  };

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    // Cleanup scroll listener on unmount
    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);

  useEffect(() => {
    const selectedChats = chats.filter((chat) => chat.id === selectedChatId);
    // console.log("selectedsssssChats", selectedChatId);
    selectedChats.map((chat, index) => {
      if (chat.messages && chat.messages.length > 0) {
        setIsDropdownDisabled(true);
        console.log("Cant change model of ", chat.id);
      } else {
        setIsDropdownDisabled(false);
      }
    });
  }, [selectedChatId, chats]);

  useEffect(() => {
    chats.map((chat) => {
      if (chat.id === selectedChatId) {
        console.log("selectedChatIiiid", selectedChatId);
        updateChatMessages(
          chat.id,
          null,
          selectedCreator,
          selectedModel,
          systemInstructions,
          temperature,
          maxTokens,
          stopSequences,
          topP,
          frequencyPenalty,
          presencePenalty
        );
      }
    });
  }, [selectedModel]);

  useEffect(() => {
    console.log("selectedChatIDchats", selectedChatId);
  }, [selectedChatId]);

  useEffect(() => {
    console.log("chatsselectedChatID", chats);
  }, [chats]);

  const [delay, setDelay] = useState(true);
  const [currentPartIndex, setCurrentPartIndex] = useState(0);

  const handlePartTypingComplete = (message) => {
    console.log("handlePartTypingComplete", message);
    if (currentPartIndex < message.length - 1) {
      setCurrentPartIndex((prev) => prev + 1);
    } else {
      handleTypingComplete(message.id);
    }
  };


  useEffect(() => {
    if (chats) {
      const timeoutDelay = setTimeout(() => {
        setDelay(false);
      }, 1500);
      return () => clearTimeout(timeoutDelay);
    }
  }, [chats]);

  return (
    <div className="chat-container">
      <div className="flex-div flex-div-between">
        <div className="flex-row items-center">
          <Dropdownllm
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            selectedCreator={selectedCreator}
            setSelectedCreator={setSelectedCreator}
            selectedLLM={selectedLLM}
            setSelectedLLM={setSelectedLLM}
            setMaxToken={setMaxToken}
            disabled={isDropdownDisabled} maxToken={maxToken} />
          <div>
            <Tooltip title="Advanced Settings">
              <IconButton
                color="inherit"
                style={{
                  color: "white",
                  height: "fit-content",
                }}
                aria-label="open right drawer"
                onClick={onOpenSettings}
              >
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="messages-container" ref={chatContainerRef}>
        {loadingChatData && selectedChatId === null && chats.length === 0 ? (
          ""
        ) : (
          <>
            {!delay ? (
              selectedChatId !== null &&
              chats
                .find((chat) => chat.id === selectedChatId)
                ?.messages.map((message) => {
                  const messageParts = extractCodeBlocks(message.text); // Extract code blocks
                  console.log("messageParts", messageParts)
                  return (
                    <div key={message.id}>
                      <div
                        className={
                          message.role === "user" ? "chat-div-user" : "chat-div"
                        }
                      >
                        <div className="avatar">
                          <img
                            src={message.role === "user" ? profpic : logo}
                            alt="avatar"
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                        <div className="content">
                          <div
                            className={
                              message.role === "user"
                                ? "content-2-user"
                                : "content-2"
                            }
                          >


                            <div
                              className={
                                message.role === "user" ? "message-text-user" : "message-text"
                              }
                            >
                              {message.text === "loading" ? (
                                <svg
                                  className="svg-rotate"
                                  viewBox="0 0 16 16"
                                  style={{ margin: "9px 5px" }}
                                >
                                  <image href={logo192} className="svg-image" />
                                </svg>
                              ) : (
                                <div
                                  className={
                                    message.role === "user" ? "message-text-user" : "message-text"
                                  }
                                >
                                  {message.role === "user" ? (
                                    message.text // Regular text for user
                                  ) : message.isNew ? (
                                    // Render message parts for new AI responses
                                    < TypingEffect
                                      text={message.text}
                                      onTypingComplete={() => handleTypingComplete(message.id)}
                                    />
                                  ) : (
                                    messageParts.map((part, index) => {
                                      if (part.type === "code") {
                                        return (
                                          <CodeBlock
                                            key={index}
                                            language={part.language}
                                            code={part.code}
                                          />
                                        );
                                      } else {
                                        return (
                                          <MarkdownRenderer key={index} content={part.content} />
                                        );
                                      }
                                    })
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <>
                <div>
                  <div className={"chat-div-user"}>
                    <div className="avatar">
                      <img
                        src={profpic}
                        alt="avatar"
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div className="content">
                      <div className={"content-2-user"}>
                        <div className={"message-text-user"}>
                          <svg
                            className="svg-rotate"
                            viewBox="0 0 16 16"
                            style={{ margin: "9px 5px" }}
                          >
                            <image href={logo192} className="svg-image" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={"chat-div"}>
                    <div className="avatar">
                      <img
                        src={logo}
                        alt="avatar"
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>

                    <div className="content">
                      <div className={"content-2"}>
                        <div className={"message-text"}>
                          <svg
                            className="svg-rotate"
                            viewBox="0 0 16 16"
                            style={{ margin: "9px 5px" }}
                          >
                            <image href={logo192} className="svg-image" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div ref={chatEndRef} />
          </>
        )}
      </div>

      <InputText
        inputText={inputText}
        setInputText={setInputText}
        selectedCreator={selectedCreator}
        selectedChatId={selectedChatId}
        chats={chats}
        updateChatMessages={updateChatMessages}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        sendMessageButtonRef={sendMessageButtonRef}
        systemInstructions={systemInstructions}
        setSystemInstructions={setSystemInstructions}
        temperature={temperature}
        setTemperature={setTemperature}
        maxTokens={maxTokens}
        setMaxTokens={setMaxTokens}
        topP={topP}
        setTopP={setTopP}
        stopSequences={stopSequences}
        setStopSequences={setStopSequences}
        frequencyPenalty={frequencyPenalty}
        setFrequencyPenalty={setFrequencyPenalty}
        presencePenalty={presencePenalty}
        setPresencePenalty={setPresencePenalty}
        disabled={isDropdownDisabled}
      />
    </div>
  );
};
export default ChatComponent;
export { MarkdownRenderer };  