import axios from "axios";
import { lookup } from "dns";
import { Session } from "inspector";
import React, { useEffect, useState } from "react";
import "src/modal.css";
import { useAuth } from "./AuthContext";
import crossicon from "src/assets/cross-2.svg";
import ban from "src/assets/ban.svg";
import * as Dialog from "@radix-ui/react-dialog";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

const urlEnv = process.env.REACT_APP_SERVER_ACCESS;

export interface SubscriptionPanelProps {
  isNewUser?: boolean;
  setIsNewUser?: React.Dispatch<React.SetStateAction<boolean>>;
  userSubstype?: number;
  userLogInId?: number;
}

const SubscriptionPanel: React.FC<SubscriptionPanelProps> = ({
  isNewUser,
  setIsNewUser,
  userSubstype,
}) => {
  const { session } = useAuth();
  const user_id = session?.user?.id;
  console.log("isNewUser", isNewUser);
  console.log("userSubstype", userSubstype);
  //

  const yearly = async () => {
    console.log(user_id);
    console.log("Yeasrly PriceId", process.env.REACT_APP_YEARLY_PRICE_ID);
    const response = await axios.post(`${urlEnv}create-checkout-session`, {
      product_id: process.env.REACT_APP_YEARLY_PRICE_ID,
      user_id: user_id,
      month_or_year: "yearly",
    });
    console.log(response);
    window.location.href = response.data.url;
  };

  const monthly = async () => {
    // const form = document.getElementById("checkout-form") as HTMLFormElement;
    // if (form) {
    //   console.log("Submitting form...");
    //   form.submit();
    // }

    console.log(user_id);
    console.log("urlEnvurlEnvurlEnvurlEnv", urlEnv);

    console.log("Monthly PriceId", process.env.REACT_APP_MONTHLY_PRICE_ID);

    const response = await axios.post(`${urlEnv}create-checkout-session`, {
      product_id: process.env.REACT_APP_MONTHLY_PRICE_ID,
      user_id: user_id,
      month_or_year: "monthly",
    });
    console.log(response);
    window.location.href = response.data.url;
  };
  const navigation = useNavigate();
  const navlogin = () => {
    window.location.href = "/login";
  };
  console.log("userSubstype", !userSubstype);

  return (
    <>
      <div className="subscription-panel">
        {userSubstype !== 0 && userSubstype !== null ? (
          <>
            <h2>Subscribed to PanelsAI</h2>
            <p className="description">
              Subscribed to PanelsAI and unlocked access to all bots, including
              OpenAI's GPT-4 and Anthropic's Claude-2-100k.
            </p>
            <div className="options">
              <div className={`option yearly ${isNewUser ? "" : "ban"}`}>
                <div className="overlay-subscribed ">
                  <Tooltip title="Already subscribed to premium">
                    <img src={ban} alt="ban" className="ban-image" />
                  </Tooltip>
                </div>
                <div className="dflexbetween">
                  <p className="title">Yearly</p>
                  <p className="save">Best value - save 17%</p>
                </div>
                <div className="dflex ">
                  <p className="old-price price">$19.00/mo</p>
                  <p className="price">$16.00/mo</p>
                </div>
                <div className="dflex">
                  <p className="old-price total-price-old">$228.00/yr</p>
                  <p className="total-price">$192.00/yr</p>
                </div>
              </div>
              <div className={`option monthly hov ${isNewUser ? "" : "ban"}`}>
                <div className="overlay-subscribed ">
                  <Tooltip title="Already subscribed to premium">
                    <img src={ban} alt="ban" className="ban-image" />
                  </Tooltip>
                </div>
                <div className="dflexbetween">
                  <p className="title">Monthly</p>
                </div>
                <div className="dflexbetween">
                  <p className="price">$19.00/mo</p>
                </div>
                <div className="dflexbetween">
                  <p className="total-price">$228.00/yr</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <h2>Subscribe to PanelsAI</h2>
            <p className="description">
              Subscribe to PanelsAI to unlock access to all bots, including
              OpenAI's GPT-4 and Anthropic's Claude-2-100k.
            </p>
            <div className="options">
              <Dialog.Root>
                {session ? (
                  <div className="option yearly hov-yearly">
                    <div className="dflexbetween">
                      <p className="title">Yearly</p>
                      <p className="save">Best value - save 17%</p>
                    </div>
                    <div onClick={yearly} className="dflex ">
                      <p className="old-price price">$19.00/mo</p>
                      <p className="price">$16.00/mo</p>
                    </div>
                    <div className="dflex">
                      <p className="old-price total-price-old">$228.00/yr</p>
                      <p className="total-price">$192.00/yr</p>
                    </div>
                  </div>
                ) : (
                  <Dialog.Trigger asChild>
                    <div className="option yearly">
                      <div className="dflexbetween">
                        <p className="title">Yearly</p>
                        <p className="save">Best value - save 17%</p>
                      </div>
                      <div className="dflex ">
                        <p className="old-price price">$19.00/mo</p>
                        <p className="price">$16.00/mo</p>
                      </div>
                      <div className="dflex">
                        <p className="old-price total-price-old">$228.00/yr</p>
                        <p className="total-price">$192.00/yr</p>
                      </div>
                    </div>
                  </Dialog.Trigger>
                )}

                <Dialog.Portal>
                  <div className="sideBar-subs-panel">
                    <div className="overlay"></div>
                    <Dialog.Overlay className="DialogOverlay" />
                    <Dialog.Content className="DialogContent">
                      <Dialog.Title className="DialogTitle"></Dialog.Title>
                      <Dialog.Description className="DialogDescription"></Dialog.Description>
                      <h2 className="h2modal">login to subscribe</h2>
                      To avail the panelsAI subscription, please login first.
                      <div
                        style={{
                          display: "flex",
                          marginTop: 25,
                          justifyContent: "flex-end",
                        }}
                      >
                        <Dialog.Close asChild>
                          <div
                            style={{
                              display: "flex",
                              marginTop: 15,
                              justifyContent: "flex-end",
                            }}
                          >
                            <Dialog.Close asChild>
                              <button
                                className="btnCloseModal"
                                onClick={navlogin}
                              >
                                Go to login now
                              </button>
                            </Dialog.Close>
                          </div>
                        </Dialog.Close>
                      </div>
                      <Dialog.Close asChild>
                        <button className="IconButton" aria-label="Close">
                          <img
                            src={crossicon}
                            alt="icon"
                            style={{
                              width: "20px",
                              height: "20px",

                              filter: "invert(1)",
                            }}
                          />
                        </button>
                      </Dialog.Close>
                    </Dialog.Content>
                  </div>
                </Dialog.Portal>
              </Dialog.Root>

              <Dialog.Root>
                {session ? (
                  <div className="option monthly hov">
                    <div className="dflexbetween">
                      <p className="title">Monthly</p>
                    </div>
                    <div onClick={monthly} className="dflexbetween">
                      <p className="price">$19.00/mo</p>
                    </div>
                    <div className="dflexbetween">
                      <p className="total-price">$228.00/yr</p>
                    </div>
                  </div>
                ) : (
                  <Dialog.Trigger asChild>
                    <div className="option monthly hov">
                      <div className="dflexbetween">
                        <p className="title">Monthly</p>
                      </div>
                      <div className="dflexbetween">
                        <p className="price">$19.00/mo</p>
                      </div>
                      <div className="dflexbetween">
                        <p className="total-price">$228.00/yr</p>
                      </div>
                    </div>
                  </Dialog.Trigger>
                )}

                <Dialog.Portal>
                  <div className="sideBar-subs-panel">
                    <div className="overlay"></div>
                    <Dialog.Overlay className="DialogOverlay" />
                    <Dialog.Content className="DialogContent">
                      <Dialog.Title className="DialogTitle"></Dialog.Title>
                      <Dialog.Description className="DialogDescription"></Dialog.Description>
                      <h2 className="h2modal">login to subscribe</h2>
                      To avail the panelsAI subscription, please login first.
                      <div
                        style={{
                          display: "flex",
                          marginTop: 25,
                          justifyContent: "flex-end",
                        }}
                      >
                        <Dialog.Close asChild>
                          <div
                            style={{
                              display: "flex",
                              marginTop: 15,
                              justifyContent: "flex-end",
                            }}
                          >
                            <Dialog.Close asChild>
                              <button
                                className="btnCloseModal"
                                onClick={navlogin}
                              >
                                Go to login now
                              </button>
                            </Dialog.Close>
                          </div>
                        </Dialog.Close>
                      </div>
                      <Dialog.Close asChild>
                        <button className="IconButton" aria-label="Close">
                          <img
                            src={crossicon}
                            alt="icon"
                            style={{
                              width: "20px",
                              height: "20px",

                              filter: "invert(1)",
                            }}
                          />
                        </button>
                      </Dialog.Close>
                    </Dialog.Content>
                  </div>
                </Dialog.Portal>
              </Dialog.Root>
            </div>
          </>
        )}
        <p className="footer-subscription">
          By subscribing, you'll enroll in automatic payments of $199.99/year
          (plus tax, if applicable). This includes unlimited messaging on
          popular bots such as ChatGPT and Claude-instant, and up to 600 and
          1000 messages on advanced bots like GPT-4 and Claude-2-100k. Once
          messaging limits are reached, messaging speed, quality, or bot
          availability may be reduced. Manage or cancel your subscription via
          Stripe. Subscriber terms apply.
        </p>
      </div>
    </>
  );
};

const SuccessDisplay = ({ sessionId }) => {
  console.log("sessionIdasd", sessionId);
  return (
    <div className="subscription-panel">
      <h2>Subscription to starter plan successful!</h2>
      <form action={`${urlEnv}create-portal-session`} method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <button id="checkout-and-portal-button" type="submit">
          Manage your billing information
        </button>
      </form>
    </div>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

const BuyCreditsPanel: React.FC<SubscriptionPanelProps> = ({
  userLogInId,
  isNewUser,
  setIsNewUser,
}) => {
  console.log("isNewUser:", isNewUser);
  console.log("userLogInId:", userLogInId);
  const { session } = useAuth();
  const user_id = session?.user?.id;

  const navlogin = () => {
    window.location.href = "/login";
  };

  const newUserPromo = async (type: string) => {
    console.log("user_id", user_id);
    console.log("userLogInId", userLogInId);
    console.log("priceID", process.env.REACT_APP_LIFETIME_PRICE_ID);

    const response = await axios.post(`${urlEnv}create-checkout-session`, {
      product_id: process.env.REACT_APP_LIFETIME_PRICE_ID,
      user_id: user_id || userLogInId,
      type: type,
    });
    console.log(response);
    window.location.href = response.data.url;
  };

  return (
    <>
      <div className="subscription-panel">
        {/* <h2>Buy PanelsAI's credits </h2>
        <p className="description">
          Buy credits to PanelsAI to unlock access to all bots, including
          OpenAI's GPT-4 and Anthropic's Claude-2-100k.
        </p> */}
        {/* <h3>Limited time offer for new users</h3> */}
        <div className="options options-remove-this-tag-if-added-another-product">
          <div
            className={`option yearly ${!session ? "" : isNewUser ? "" : "ban"
              }`}
          >
            {!session ? (
              ""
            ) : isNewUser ? (
              ""
            ) : (
              <Tooltip title="Exclusive for new users only">
                <img src={ban} alt="ban" className="ban-image" />
              </Tooltip>
            )}

            <Dialog.Root>
              <div onClick={() => newUserPromo("lifetime")}>
                <div className="dflexbetween">
                  <p className="title">Lifetime</p>
                  <p className="save text-black">Limited</p>
                </div>
                <div className="dflexbetween">
                  <p className="price">$1</p>
                </div>
                <div className="dflexbetween">
                  <p className="total-price"> 2,000,000 PanelsAI credits</p>
                </div>
              </div>

              <Dialog.Portal>
                <div className="sideBar-subs-panel">
                  <div className="overlay"></div>
                  <Dialog.Overlay className="DialogOverlay" />
                  <Dialog.Content className="DialogContent">
                    <Dialog.Title className="DialogTitle"></Dialog.Title>
                    <Dialog.Description className="DialogDescription"></Dialog.Description>
                    <h2 className="h2modal">login to subscribe</h2>
                    To avail the panelsAI subscription, please login first.
                    <div
                      style={{
                        display: "flex",
                        marginTop: 25,
                        justifyContent: "flex-end",
                      }}
                    >
                      <Dialog.Close asChild>
                        <div
                          style={{
                            display: "flex",
                            marginTop: 15,
                            justifyContent: "flex-end",
                          }}
                        >
                          <Dialog.Close asChild>
                            <button
                              className="btnCloseModal"
                              onClick={navlogin}
                            >
                              Go to login now
                            </button>
                          </Dialog.Close>
                        </div>
                      </Dialog.Close>
                    </div>
                    <Dialog.Close asChild>
                      <button className="IconButton" aria-label="Close">
                        <img
                          src={crossicon}
                          alt="icon"
                          style={{
                            width: "20px",
                            height: "20px",

                            filter: "invert(1)",
                          }}
                        />
                      </button>
                    </Dialog.Close>
                  </Dialog.Content>
                </div>
              </Dialog.Portal>
            </Dialog.Root>
          </div>

          {/* <div
            className={`option monthly hov ${
              !session ? "" : isNewUser.isNewUser ? "" : "ban"
            }`}
          >
            {!session ? (
              ""
            ) : isNewUser.isNewUser ? (
              ""
            ) : (
              <Tooltip title="Exclusive for new users only">
                <img src={ban} alt="ban" className="ban-image" />
              </Tooltip>
            )}

            <Dialog.Root>
              {session ? (
                isNewUser.isNewUser ? (
                  <div>
                    <div className="dflexbetween">
                      <p className="title">Expires in a month</p>
                      <p className="save text-black">Limited</p>
                    </div>
                    <div
                      className="dflexbetween"
                      onClick={() => newUserPromo("monthly")}
                    >
                      <p className="price">$1</p>
                    </div>
                    <div className="dflexbetween">
                      <p className="total-price">
                        - 4,000,000 PanelsAI credits
                      </p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="dflexbetween">
                      <p className="title">Expires in a month</p>
                      <p className="save text-black">Limited</p>
                    </div>
                    <div
                      className="dflexbetween"
                      //onClick={} //open modal alert
                    >
                      <p className="price">$1</p>
                    </div>
                    <div className="dflexbetween">
                      <p className="total-price">
                        - 4,000,000 PanelsAI credits
                      </p>
                    </div>
                  </div>
                )
              ) : (
                <Dialog.Trigger asChild>
                  <div>
                    <div className="dflexbetween">
                      <p className="title">Expires in a month</p>
                      <p className="save text-black">Limited</p>
                    </div>
                    <div className="dflexbetween">
                      <p className="price">$1</p>
                    </div>
                    <div className="dflexbetween">
                      <p className="total-price">
                        - 4,000,000 PanelsAI credits
                      </p>
                    </div>
                  </div>
                </Dialog.Trigger>
              )}

              <Dialog.Portal>
                <div className="sideBar-subs-panel">
                  <div className="overlay"></div>
                  <Dialog.Overlay className="DialogOverlay" />
                  <Dialog.Content className="DialogContent">
                    <Dialog.Title className="DialogTitle"></Dialog.Title>
                    <Dialog.Description className="DialogDescription"></Dialog.Description>
                    <h2 className="h2modal">login to subscribe</h2>
                    To avail the panelsAI subscription, please login first.
                    <div
                      style={{
                        display: "flex",
                        marginTop: 25,
                        justifyContent: "flex-end",
                      }}
                    >
                      <Dialog.Close asChild>
                        <div
                          style={{
                            display: "flex",
                            marginTop: 15,
                            justifyContent: "flex-end",
                          }}
                        >
                          <Dialog.Close asChild>
                            <button
                              className="btnCloseModal"
                              onClick={navlogin}
                            >
                              Go to login now
                            </button>
                          </Dialog.Close>
                        </div>
                      </Dialog.Close>
                    </div>
                    <Dialog.Close asChild>
                      <button className="IconButton" aria-label="Close">
                        <img
                          src={crossicon}
                          alt="icon"
                          style={{
                            width: "20px",
                            height: "20px",

                            filter: "invert(1)",
                          }}
                        />
                      </button>
                    </Dialog.Close>
                  </Dialog.Content>
                </div>
              </Dialog.Portal>
            </Dialog.Root>
          </div> */}
        </div>
        {/* <p className="footer-subscription">
          This includes unlimited messaging on popular bots such as ChatGPT and
          Claude-instant, and up to 600 and 1000 messages on advanced bots like
          GPT-4 and Claude-2-100k. Once messaging limits are reached, messaging
          speed, quality, or bot availability may be reduced.
        </p> */}
      </div>
    </>
  );
};

const CustomTopUp: React.FC = () => {
  const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
  const { session } = useAuth();
  const user_id = session?.user?.id;
  const minAmount = 5;
  const maxAmount = 100;
  const [amount, setAmount] = useState({ amount: "5" });
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isFocused, setIsFocused] = useState(false);

  const [creditsConversion, setCreditsConversion] = useState<any>("");
  const [loadingCreditsConversion, setLoadingCreditsConversion] =
    useState(true);
  const [totalCredits, setTotalCredits] = useState<any>(0);
  // const [successMessage, setSuccessMessage] = useState<string>("");

  const topUp = async (type: string, amount: number) => {
    console.log("user_id", user_id);
    const response = await axios.post(`${urlEnv}create-checkout-session`, {
      user_id: user_id,
      type: type,
      amount: amount,
    });
    console.log(response);
    window.location.href = response.data.url;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (!errorMessage) {
      // alert(`Successfully added $${amount.amount} to your balance!`);
      topUp("lifetime", parseFloat(amount.amount));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAmount({ ...amount, [name]: value });
    validateAmount(value);
  };

  const validateAmount = (value: string): void => {
    let numericValue = parseFloat(value);
    //console.log("numericValue", numericValue);

    if (
      isNaN(numericValue) ||
      numericValue < minAmount ||
      numericValue > maxAmount
    ) {
      setErrorMessage(
        `Please enter an amount between $${minAmount} and $${maxAmount}.`
      );
    } else {
      setErrorMessage("");
      let total = numericValue / creditsConversion.itemized_per_credits;
      setTotalCredits(total);
    }
  };

  const fetchCreditsConversion = async () => {
    setLoadingCreditsConversion(true);
    const response = await axios.get(`${urlEnv}fetch-credits-conversion`);

    if (response) {
      setCreditsConversion(response.data);
      //console.log("fetchCreditsConversion", response.data);

      if (parseFloat(amount.amount) === minAmount) {
        let total = minAmount / response.data.itemized_per_credits;
        //console.log("totalshits", total);
        setTotalCredits(total);
      }
    }
    setLoadingCreditsConversion(false);
  };

  useEffect(() => {
    fetchCreditsConversion();
  }, []);

  return (
    <>
      <div className="subscription-panel">
        <h2>Add to credits balance </h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="amount" className="topup-label">
            Amount to add
          </label>
          <div className="top-up-modal">
            <span
              className={`currency-symbol ${isFocused ? "currency-symbol-focus" : ""
                }`}
            >
              $
            </span>
            <input
              type="number"
              id="amount"
              name="amount"
              // placeholder={`Enter amount between $${minAmount} and $${maxAmount}`}
              value={amount.amount}
              onChange={handleChange}
              className={`topUp-input ${errorMessage ? "input-error" : ""} `}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>
          {errorMessage ? (
            <p className="error-message error-top-up">{errorMessage}</p>
          ) : (
            <p className="total-top-up-credits">
              = {loadingCreditsConversion ? "" : totalCredits.toLocaleString()}{" "}
              lifetime Credits
            </p>
          )}
          <div className="top-up-button-div">
            <button type="submit" className="add-credit-btn button-top-up">
              Add Credit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

interface AutoRechargeSettingsProps {
  onSave: (methodId: string) => void;
  existingPaymentMethodId: string | null;
  autoData: any;
}

const AutoRechargeSettings: React.FC<AutoRechargeSettingsProps> = ({
  onSave,
  existingPaymentMethodId,
  autoData,
}) => {
  console.log("existingPaymentMethodId", existingPaymentMethodId);
  const { session } = useAuth();
  const user_id = session?.user?.id;
  const user_email = session?.user?.email;
  const [autoRecharge, setAutoRecharge] = useState(() => {
    return autoData.active == "ON" ? true : false;
  });

  // const [amount, setAmount] = useState({
  //   limitMinAmount: "5",
  //   rechargeAmount: "100",
  // });
  const minAmount = 5;
  const maxAmount = 100;
  const [thresholdAmount, setThresholdAmount] = useState<string | "">(() => {
    return autoData.active == "ON" ? autoData.threshold_amount : minAmount;
  });
  const [rechargeAmount, setRechargeAmount] = useState<string | "">(() => {
    return autoData.active == "ON" ? autoData.recharge_amount : "100";
  });
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorMessage1, setErrorMessage1] = useState<string>("");

  const [totalCredits, setTotalCredits] = useState<any>(0);
  const [creditsConversion, setCreditsConversion] = useState<any>("");

  const [isCardValid, setIsCardValid] = useState(false);
  const [loadingCard, setIsLoadingCard] = useState(false);
  const [loadingSaveAutoRecharge, setIsloadingSaveAutoRecharge] =
    useState(false);
  const [paymentMethod, setPaymentMethod] = useState<any>(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleThresholdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setThresholdAmount(e.target.value);
    validateAmountThreshold(e.target.value);
  };

  const handleRechargeAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRechargeAmount(e.target.value);
    validateAmountRecharge(e.target.value);
  };

  const handleSave = async () => {
    setIsloadingSaveAutoRecharge(true);
    // Here you would handle saving the data, maybe make an API call
    console.log("Auto Recharge Settings Saved:", {
      autoRecharge,
      thresholdAmount,
      rechargeAmount,
    });

    try {
      if (!errorMessage || !errorMessage1) {
        const response = await axios.post(`${urlEnv}save-auto-recharge`, {
          threshold_amount: thresholdAmount,
          recharge_amount: rechargeAmount,
          active: autoRecharge,
          user_id: user_id,
        });

        if (response) {
          console.log("response auto recharge:", response.data);
        }
      }
    } catch (error) {
      console.error("handleSave error:", error);
    }
    setIsloadingSaveAutoRecharge(false);
  };

  const validateAmountThreshold = (value: string): void => {
    let numericValue = parseFloat(value);
    //console.log("numericValue", numericValue);

    if (
      isNaN(numericValue) ||
      numericValue < minAmount ||
      numericValue > maxAmount
    ) {
      setErrorMessage(
        `Please enter an amount between $${minAmount} and $${maxAmount}.`
      );
    } else {
      setErrorMessage("");
    }
  };

  const validateAmountRecharge = (value: string): void => {
    let numericValue = parseFloat(value);
    console.log("validateAmountRecharge", numericValue);

    if (
      isNaN(numericValue) ||
      numericValue < minAmount ||
      numericValue > maxAmount
    ) {
      setErrorMessage1(
        `Please enter an amount between $${minAmount} and $${maxAmount}.`
      );
    } else {
      setErrorMessage1("");
    }
  };

  const handleSavePaymentMethod = async () => {
    setIsLoadingCard(true);
    const response = await axios.post(`${urlEnv}create-setup-intent`, {
      user_id: user_id,
      email: user_email,
    });

    if (response.data.clientSecret) {
      console.log("handleSavePaymentMethod", response.data.clientSecret);
    }

    if (!stripe || !elements) return;

    const result = await stripe.confirmCardSetup(response.data.clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      console.error(result.error.message);
    } else {
      console.log("resultresultresultresult", result);

      const payment_method = result.setupIntent?.payment_method;
      console.log("Payment method saved!", result.setupIntent.payment_method);

      if (typeof payment_method === "string") {
        await axios.post(`${urlEnv}save-payment-method`, {
          userId: user_id, // Pass the user ID
          payment_method, // Pass the payment method ID
        });
        onSave(payment_method);
      } else {
        console.error("Payment method ID is not available:", payment_method);
      }
    }
    setIsLoadingCard(false);
  };

  const handleCardChange = (event: any) => {
    setIsCardValid(event.complete); // `event.complete` is true if the card info is valid
  };
  const fetchPaymentMethod = async () => {
    if (existingPaymentMethodId) {
      try {
        // Call your API to fetch the payment method details
        const response = await axios.get(`${urlEnv}get-payment-method`, {
          params: {
            existingPaymentMethodId: existingPaymentMethodId,
            user_id: user_id,
          },
        });
        console.log(
          "response.dataasdasdasdqweqwdasad",
          response.data.paymentMethod.customer
        );
        // Check if payment method data is returned and set it to state
        if (response.data && response.data.paymentMethod) {
          console.log(
            "response.data.paymentMethod",
            response.data.paymentMethod
          );
          setPaymentMethod(response.data.paymentMethod);
        }
      } catch (error) {
        console.error("Error fetching payment method:", error);
      }
    }
  };

  const fetchPaymentMethodFromDB = async () => {
    try {
      const response = await axios.get(`${urlEnv}get-payment-method-from-db`, {
        params: {
          user_id: user_id,
        },
      });

      if (response.data) {
        console.log("fetchPaymentMethodFromDB", response.data);

        onSave(response.data);
      }
    } catch (error) {
      // console.error("Error fetching payment method:", error);
    }
  };

  useEffect(() => {
    fetchPaymentMethod();
  }, [existingPaymentMethodId]);

  useEffect(() => {
    if (!existingPaymentMethodId) {
      fetchPaymentMethodFromDB();
    }
  }, []);

  console.log("paymentMethod", paymentMethod);
  return (
    <>
      <h3>Auto recharge settings</h3>
      <div className="form-group flex">
        <Switch
          checked={autoRecharge}
          onChange={() => setAutoRecharge(!autoRecharge)}
          inputProps={{ "aria-label": "controlled" }}
          className="color-of-toggle-auto-recharge"
        />
        <label className="flex">
          Yes, automatically recharge my card when my credit balance falls below
          a threshold
        </label>
      </div>
      <div className="auto-topup-error">
        {errorMessage || errorMessage1 ? (
          <p className="error-message error-top-up">{errorMessage}</p>
        ) : (
          ""
        )}
      </div>

      <div className="form-group">
        <label htmlFor="threshold">When credit balance goes below</label>

        <div className="top-up-modal">
          <span
            className={`currency-symbol ${isFocused ? "currency-symbol-focus" : ""
              }`}
          >
            $
          </span>
          <input
            type="number"
            id="threshold"
            value={thresholdAmount}
            className={`topUp-input ${errorMessage ? "input-error" : ""} `}
            onChange={handleThresholdChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            readOnly={!autoRecharge}
          />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="recharge-amount">Bring credit balance back up to</label>

        <div className="top-up-modal">
          <span
            className={`currency-symbol ${isFocused1 ? "currency-symbol-focus" : ""
              }`}
          >
            $
          </span>

          <input
            type="number"
            id="recharge-amount"
            value={rechargeAmount}
            onChange={handleRechargeAmountChange}
            className={`topUp-input ${errorMessage1 ? "input-error" : ""} `}
            onFocus={() => setIsFocused1(true)}
            onBlur={() => setIsFocused1(false)}
            readOnly={!autoRecharge}
          />
        </div>
      </div>

      <div className={`flex flex-col card-set-recharge-space `}>
        <div
          className={`card-element-auto-recharge ${paymentMethod ? "trans-back" : ""
            }`}
        >
          {!paymentMethod ? (
            <CardElement onChange={handleCardChange} />
          ) : (
            <div className="existing-card">
              <h4>Existing Card:</h4>
              <p>**** **** **** {paymentMethod.card.last4}</p>
              <p>
                Expiry: {paymentMethod.card.exp_month}/
                {paymentMethod.card.exp_year}
              </p>
              <p>{paymentMethod.card.brand}</p>
            </div>
          )}
        </div>
        <div className="flex justify-end">
          {paymentMethod ? (
            ""
          ) : (
            <button
              onClick={handleSavePaymentMethod}
              className={`save-button button-auto-recharge-save ${!isCardValid ? "invalid-card" : ""
                }`}
              disabled={!isCardValid || loadingCard}
            >
              {loadingCard ? "Saving" : "Save Card for Automatic Top-Up"}
            </button>
          )}
        </div>
      </div>

      <div className="button-group">
        <Dialog.Close asChild>
          <button
            onClick={handleSave}
            className={`save-button ${!paymentMethod ? "invalid-card" : ""}`}
            disabled={!paymentMethod || loadingSaveAutoRecharge}
          >
            {loadingSaveAutoRecharge ? "Saving" : "Save settings"}
          </button>
        </Dialog.Close>
        <Dialog.Close asChild>
          <button className="cancel-plan-btn">Cancel</button>
        </Dialog.Close>
      </div>
    </>
  );
};

// const check = async (sessionId) => {
//   console.log(sessionId);
//   try {
//     const response = await axios.post(`${urlEnv}check-session-id`, {
//       session_id: sessionId,
//     });
//     console.log("response", response);
//   } catch (error) {
//     console.log("front end error s", error);
//   }
// };

function SubsPanel() {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  if (!success && message === "") {
    return <SubscriptionPanel />;
  } else if (success && sessionId !== "") {
    // const response = await check(sessionId);
    // console.log("response function", response);
    return;
    // (
    //   <>
    //     <SubscriptionPanel /> <SuccessDisplay sessionId={sessionId} />
    //   </>
    // );
  } else {
    return (
      <>
        <Message message={message} />
        <SubscriptionPanel />
      </>
    );
  }
}

export { SubsPanel, BuyCreditsPanel, CustomTopUp, AutoRechargeSettings };
export default SubscriptionPanel;
